<template>
  <div>
    <div>
      <div class="container1">
        <div class="rectangle1">
          <Navigationen></Navigationen>
        </div>
      </div>
    </div>
    <div class="addCase-header">
      <div class="addCase-header-1">
        <div class="addCase-header-item headerActive">
          <span
            class="addCase-header-item-divide-left"
            style="display: none"
          ></span>
          <img style="width: 40px" src="@/img/CaseProcess-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Patient information</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/diagnosis-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Diagnostic information</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/information-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Treatment plan</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/information-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div></div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/download-1.png" />
          <span
            class="addCase-header-item-divide-right"
            style="display: none"
          ></span>
          <div>Data upload</div>
        </div>
      </div>
    </div>
    <div class="addCase-content">
      <div class="patientInfoNew">
        <div class="patientInfoNew-content">
          <p
            style="
              font-size: 20px;
              line-height: 160%;
              display: flex;
              align-items: center;
              color: rgb(0, 0, 0);
            "
          >
            1.Patient information
          </p>
          <p
            style="
              width: 500px;
              font-size: 13px;
              line-height: 160%;
              color: rgb(85, 85, 85);
              margin-top: 8px;
            "
          >
            You can add up to two lines of filling help or notes here. If there
            is no setting, it will be blank. The text space here is designed to
            effectively solve the matters needing attention in data filling and
            reduce errors.
          </p>
          <img
            src="@/img/beame.png"
            style="
              width: 400px;
              height: 64px;
              position: absolute;
              top: 20px;
              right: 20px;
            "
          />
          <div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item
                label="Patient Name"
                prop="name"
                label-width="100px"
                style="margin-right: 100px; display: inline-block; width: 240px"
              >
                <el-input
                  style="width: 240px"
                  v-model="ruleForm.name"
                ></el-input>
              </el-form-item>
              <el-form-item
                style="
                  display: inline-block;
                  width: 240px;
                  margin: 0 50px 0 80px;
                "
                label="Attending doctor"
                prop="Doctor"
              >
                <el-select
                  @change="GetAddress"
                  v-model="ruleForm.Doctor"
                  placeholder="please choose"
                >
                  <el-option
                    v-for="item in GetDoctor"
                    :key="item.id"
                    :label="item.name"
                    :value="item.uuid"
                  ></el-option>
                  <!-- <el-option label="医生二" value="医生二"></el-option> -->
                </el-select>
              </el-form-item>
              <el-form-item
                style="display: inline-block; width: 420px"
                label="gender"
                prop="resource"
              >
                <el-radio-group v-model="ruleForm.resource" size="medium">
                  <el-radio border label="1">male</el-radio>
                  <el-radio border label="2">female</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="clinic"
                :disabled="true"
                prop="ClinicName"
                label-width="100px"
                style="display: inline-block; width: 300px; margin-right: 50px"
              >
                <el-input
                  :disabled="true"
                  v-model="ruleForm.ClinicName"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item
                style="display: inline-block; width: 350px"
                label="date of birth"
                required
                label-width="100px"
              >
                <el-col :span="11">
                  <el-form-item prop="value-format">
                    <el-date-picker
                      type="date"
                      placeholder="Select date"
                      v-model="ruleForm.date1"
                      style="width: 200px"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item
                style="display: inline-block; float: right; margin-right: 90px"
                label="address"
                prop="region"
              >
                <el-select
                  style="width: 350px"
                  v-model="ruleForm.region"
                  placeholder="Please select an address"
                >
                  <el-option
                    v-for="item in DefaultAddress"
                    :key="item.id"
                    :label="item.full_address"
                    :value="item.uuid"
                  ></el-option>
                  <el-button type="text" @click="dialogFormVisible = true"
                    >- - Add temporary shipping address -
                  </el-button>
                </el-select>
                <el-dialog
                  title="Add mailing address"
                  :visible.sync="dialogFormVisible"
                >
                  <el-form :model="form" :rules="rules2" ref="form">
                    <p style="line-height: 14px; margin: 0px 0px 20px 40px">
                      Create a new address to receive orthodontic appliances or
                      related documents
                    </p>

                    <el-form-item
                      style="margin-bottom: 22px"
                      prop="clinic"
                      label="Clinic name"
                      :label-width="formLabelWidth"
                    >
                      <el-input
                        placeholder="Clinic cannot be empty"
                        style="width: 400px"
                        v-model="form.clinic"
                        autocomplete="off"
                      ></el-input> </el-form-item
                    ><el-form-item
                      style="margin-bottom: 22px"
                      prop="consignee"
                      label="The recipient's name"
                      :label-width="formLabelWidth"
                    >
                      <el-input
                        placeholder="Recipient name cannot be empty"
                        style="width: 400px"
                        v-model="form.consignee"
                        autocomplete="off"
                      ></el-input> </el-form-item
                    ><el-form-item
                      style="margin-bottom: 22px"
                      prop="phone"
                      label="The recipient's phone"
                      :label-width="formLabelWidth"
                    >
                      <el-input
                        placeholder="Mobile phone number, landline number, extension number, etc."
                        style="width: 400px"
                        v-model="form.phone"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      style="margin-bottom: 22px"
                      prop="address"
                      label="receiver's address"
                      :label-width="formLabelWidth"
                    >
                      <el-input
                        placeholder="Please enter the recipient's address, down to the house number."
                        style="width: 400px"
                        v-model="form.address"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      style="margin-bottom: 22px"
                      prop="postcode"
                      label="post code"
                      :label-width="formLabelWidth"
                    >
                      <el-input
                        placeholder="Please enter your location zip code"
                        style="width: 400px"
                        v-model="form.postcode"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button
                      style="margin-right: 100px"
                      @click="dialogFormVisible = false"
                      >Cancel</el-button
                    >
                    <el-button
                      type="primary"
                      @click="dialogFormVisible2('form')"
                      >Sure</el-button
                    >
                  </div>
                </el-dialog>
              </el-form-item>
              <div
                style="
                  border: 1px dashed rgb(196, 196, 196);
                  margin: 35px 0 35px 0;
                "
              ></div>
              <el-form-item label="Main Request">
                <el-input
                  placeholder="Enter details"
                  style="width: 400px; height: 200px"
                  type="textarea"
                  v-model="ruleForm.desc"
                ></el-input>
              </el-form-item>
              <div
                style="
                  border: 1px dashed rgb(196, 196, 196);
                  margin: 35px 0 35px 0;
                "
              ></div>

              <el-form-item label="Related photos">
                <div style="display: flex">
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 1)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_1"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Front <br /><br />
                        + upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_1" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_1, 1)"
                      ></i>
                      <img class="image" :src="imageUrl_1" />
                    </div>
                  </div>
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 2)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_2"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Smile<br /><br />
                        + upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_2" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_2, 2)"
                      ></i>
                      <img class="image" :src="imageUrl_2" />
                    </div>
                  </div>
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 3)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_3"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Profile<br /><br />
                        + upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_3" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_3, 3)"
                      ></i>
                      <img class="image" :src="imageUrl_3" />
                    </div>
                  </div>
                </div>
                <div style="display: flex">
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 4)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_4"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Frontal <br /><br />+ upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_4" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_4, 4)"
                      ></i>
                      <img class="image" :src="imageUrl_4" />
                    </div>
                  </div>
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 5)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_5"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Upper Occlusal<br /><br />
                        + upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_5" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_5, 5)"
                      ></i>
                      <img class="image" :src="imageUrl_5" />
                    </div>
                  </div>
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 6)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_6"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Lower Occlusal<br /><br />
                        + upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_6" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_6, 6)"
                      ></i>
                      <img class="image" :src="imageUrl_6" />
                    </div>
                  </div>
                </div>
                <div style="display: flex">
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 7)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_7"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Left Buccal<br /><br />
                        + upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_7" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_7, 7)"
                      ></i>
                      <img class="image" :src="imageUrl_7" />
                    </div>
                  </div>
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 8)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_8"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Right Buccal<br /><br />
                        + upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_8" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_8, 8)"
                      ></i>
                      <img class="image" :src="imageUrl_8" />
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="Related photos">
                <div style="display: flex">
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 9)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_9"
                    >
                      <p class="upload-p" size="small" type="primary">
                        X-ray<br /><br />
                        + upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_9" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_9, 9)"
                      ></i>
                      <img class="image" :src="imageUrl_9" />
                    </div>
                  </div>
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 10)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_10"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Frontal<br /><br />
                        + upload
                      </p>
                    </el-upload>
                    <div
                      v-if="imageUrl_10"
                      style="width: 100%; font-size: 32px"
                    >
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_10, 10)"
                      ></i>
                      <img class="image" :src="imageUrl_10" />
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="Related photos">
                <div style="display: flex">
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 11)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_11"
                    >
                      <p class="upload-p" size="small" type="primary">
                        + upload
                      </p>
                    </el-upload>
                    <div
                      v-if="imageUrl_11"
                      style="width: 100%; font-size: 32px"
                    >
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_11, 11)"
                      ></i>
                      <img class="image" :src="imageUrl_11" />
                    </div>
                  </div>
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 12)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_12"
                    >
                      <p class="upload-p" size="small" type="primary">
                        + upload
                      </p>
                    </el-upload>
                    <div
                      v-if="imageUrl_12"
                      style="width: 100%; font-size: 32px"
                    >
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_12, 12)"
                      ></i>
                      <img class="image" :src="imageUrl_12" />
                    </div>
                  </div>
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 13)"
                      :before-upload="beforeUpload"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_13"
                    >
                      <p class="upload-p" size="small" type="primary">
                        + upload
                      </p>
                    </el-upload>
                    <div
                      v-if="imageUrl_13"
                      style="width: 100%; font-size: 32px"
                    >
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_13, 13)"
                      ></i>
                      <img class="image" :src="imageUrl_13" />
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div class="patientInfoNew_bottom1">
          <div class="patientInfoNew_bottom_content">
            <button
              @click="Jump('/zh-en/Home')"
              type="button"
              class="button-return"
            >
              <span>return</span>
            </button>
            <button
              @click="submitForm('ruleForm')"
              type="button"
              class="button-Staging"
            >
              <span>Staging</span>
            </button>
            <button disabled="disabled" type="button" class="button-step">
              <span>previous step</span>
            </button>
            <button
              @click="submitForm('ruleForm')"
              type="button"
              class="button-step-colour"
            >
              <span>next step</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navigationen from "@/components/Navigationen.vue";

import {
  ShippingAddress,
  GetShippingAddress,
  GetDoctor,
  patientInformation,
  getRecord,
  DeleteFile,
} from "@/api/outpatient";

export default {
  metaInfo: {
    link: [],
  },
  components: {
    Navigationen,
  },
  data() {
    return {
      disabled: false,
      dialogFormVisible: false,
      form: {
        clinic: "",
        consignee: "",
        phone: "",
        address: "",
        postcode: "",
      },
      formLabelWidth: "120px",

      ruleForm: {
        name: "",
        region: "",
        Doctor: "",
        ClinicName: "自有光口腔诊所",
        date1: "",
        resource: "",
        desc: "",
      },
      rules2: {
        clinic: [
          {
            required: true,
            message: "Clinic cannot be empty",
            trigger: "blur",
          },
        ],
        consignee: [
          { required: true, message: "Name cannot be empty", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "Phone cannot be empty", trigger: "blur" },
        ],
        address: [
          {
            required: true,
            message: "Address cannot be empty",
            trigger: "blur",
          },
        ],
      },
      rules: {
        name: [
          { required: true, message: "Name cannot be empty", trigger: "blur" },
        ],
        // ClinicName: [
        //   { required: true, message: "诊所不能为空", trigger: "blur" },
        // ],

        Doctor: [
          {
            required: true,
            message: "Please select a doctor",
            trigger: "change",
          },
        ],
        region: [
          {
            required: true,
            message: "Please select an address",
            trigger: "change",
          },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "Please select a date",
            trigger: "change",
          },
        ],
        // type: [
        //   {
        //     type: "array",
        //     required: true,
        //     message: "请至少选择一个活动性质",
        //     trigger: "change",
        //   },
        // ],
        resource: [
          {
            required: true,
            message: "Please select gender",
            trigger: "change",
          },
        ],
      },
      DefaultAddress: [],
      PatientData: [],
      GetDoctor: [],
      fileList: [],
      myHeaders: {},
      imageUrl_1: "",
      imageUrl_2: "",
      imageUrl_3: "",
      imageUrl_4: "",
      imageUrl_5: "",
      imageUrl_6: "",
      imageUrl_7: "",
      imageUrl_8: "",
      imageUrl_9: "",
      imageUrl_10: "",
      imageUrl_11: "",
      imageUrl_12: "",
      imageUrl_13: "",
      imageUuid_1: "",
      imageUuid_2: "",
      imageUuid_3: "",
      imageUuid_4: "",
      imageUuid_5: "",
      imageUuid_6: "",
      imageUuid_7: "",
      imageUuid_8: "",
      imageUuid_9: "",
      imageUuid_10: "",
      imageUuid_11: "",
      imageUuid_12: "",
      imageUuid_13: "",
      PatientID: "",
      photo_1: {},
      photo_2: {},
      photo_3: {},
      photo_4: {},
      photo_5: {},
      photo_6: {},
      photo_7: {},
      photo_8: {},
      photo_9: {},
      photo_10: {},
      photo_11: {},
      photo_12: {},
      photo_13: {},
      uploadUrl: "https://cases.mybeame.info/api/file/upload",
      // uploadUrl: "http://172.18.80.32/api/file/upload",
    };
  },

  created() {
    // 上传头;
    var data = JSON.parse(sessionStorage.getItem("token"));
    this.myHeaders.Authorization = "Bearer " + data;

    // 获取医生
    GetDoctor().then((res) => {
      this.GetDoctor = res.data;
    });

    // 获取患者所有信息
    // debugger;
    if (
      localStorage.getItem("PatientID") != null &&
      localStorage.getItem("PatientID") != ""
    ) {
      this.PatientID = JSON.parse(localStorage.getItem("PatientID"));
      getRecord(this.PatientID).then((res) => {
        this.PatientData = res.data;
        this.ruleForm.name = res.data.name;
        this.ruleForm.Doctor = res.data.user_uid;
        this.ruleForm.resource = res.data.gender;
        this.ruleForm.date1 = res.data.birthday;
        this.ruleForm.region = res.data.address_uid;
        this.ruleForm.desc = res.data.remarks;
        this.imageUrl_1 = res.data.frontal_avatar_url;
        this.imageUrl_2 = res.data.frontal_smile_img_url;
        this.imageUrl_3 = res.data.right_side_img_url;
        this.imageUrl_4 = res.data.front_teeth_img_url;
        this.imageUrl_5 = res.data.upper_teeth_img_url;
        this.imageUrl_6 = res.data.lower_teeth_img_url;
        this.imageUrl_7 = res.data.left_mouth_img_url;
        this.imageUrl_8 = res.data.right_mouth_img_url;
        this.imageUrl_9 = res.data.full_jaw_curved_slice_img_url;
        this.imageUrl_10 = res.data.lateral_cephalometric_radiograph_img_url;
        this.imageUrl_11 = res.data.other1_img_url;
        this.imageUrl_12 = res.data.other2_img_url;
        this.imageUrl_13 = res.data.other3_img_url;
        this.imageUuid_1 = res.data.frontal_avatar;
        this.imageUuid_2 = res.data.frontal_smile_img;
        this.imageUuid_3 = res.data.right_side_img;
        this.imageUuid_4 = res.data.front_teeth_img;
        this.imageUuid_5 = res.data.upper_teeth_img;
        this.imageUuid_6 = res.data.lower_teeth_img;
        this.imageUuid_7 = res.data.left_mouth_img;
        this.imageUuid_8 = res.data.right_mouth_img;
        this.imageUuid_9 = res.data.full_jaw_curved_slice_img;
        this.imageUuid_10 = res.data.lateral_cephalometric_radiograph_img;
        this.imageUuid_11 = res.data.other1_img;
        this.imageUuid_12 = res.data.other2_img;
        this.imageUuid_13 = res.data.other3_img;
        localStorage.setItem("ProgrammeID", JSON.stringify(res.data.tp_uid));

        // 获取配送地址
        GetShippingAddress(this.ruleForm.Doctor).then((res) => {
          this.DefaultAddress = res.data;
        });
      });
    }
  },
  mounted() {
    this.photo_1 = { uuid: this.PatientID, type: "1" };
    this.photo_2 = { uuid: this.PatientID, type: "2" };
    this.photo_3 = { uuid: this.PatientID, type: "3" };
    this.photo_4 = { uuid: this.PatientID, type: "4" };
    this.photo_5 = { uuid: this.PatientID, type: "5" };
    this.photo_6 = { uuid: this.PatientID, type: "6" };
    this.photo_7 = { uuid: this.PatientID, type: "7" };
    this.photo_8 = { uuid: this.PatientID, type: "8" };
    this.photo_9 = { uuid: this.PatientID, type: "9" };
    this.photo_10 = { uuid: this.PatientID, type: "10" };
    this.photo_11 = { uuid: this.PatientID, type: "11" };
    this.photo_12 = { uuid: this.PatientID, type: "12" };
    this.photo_13 = { uuid: this.PatientID, type: "13" };
  },
  methods: {
    Jump(route) {
      this.$router.push(route);
    },
    GetAddress() {
      // 获取配送地址
      var uuid = this.ruleForm.Doctor;

      GetShippingAddress(uuid).then((res) => {
        this.DefaultAddress = res.data;
        this.ruleForm.region = "";
      });
    },
    // 发送 患者信息
    submitForm(formName) {
      // this.$router.push("/addCase/deagonsticMesNew");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var data = {
            name: this.ruleForm.name,
            user_uid: this.ruleForm.Doctor,
            gender: this.ruleForm.resource,
            birthday: this.ruleForm.date1,
            address_uid: this.ruleForm.region,
            uuid: this.PatientID,
            remarks: this.ruleForm.desc,
            frontal_avatar: this.imageUuid_1,
            frontal_smile_img: this.imageUuid_2,
            right_side_img: this.imageUuid_3,
            front_teeth_img: this.imageUuid_4,
            upper_teeth_img: this.imageUuid_5,
            lower_teeth_img: this.imageUuid_6,
            left_mouth_img: this.imageUuid_7,
            right_mouth_img: this.imageUuid_8,
            full_jaw_curved_slice_img: this.imageUuid_9,
            lateral_cephalometric_radiograph_img: this.imageUuid_10,
            other1_img: this.imageUuid_11,
            other2_img: this.imageUuid_12,
            other3_img: this.imageUuid_13,
          };
          patientInformation(data).then((res) => {
            if (res.code == 200) {
              localStorage.setItem("PatientID", JSON.stringify(res.data.uuid));
              localStorage.setItem(
                "DoctorID",
                JSON.stringify(this.ruleForm.Doctor)
              );

              this.$router.push("/zh-en/addCase/deagonsticMesNew");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 新增配送地址
    dialogFormVisible2(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          var Data = {
            clinic: this.form.clinic,
            consignee: this.form.consignee,
            phone: this.form.phone,
            address: this.form.address,
            postcode: this.form.postcode,
            uuid: this.ruleForm.Doctor,
          };
          ShippingAddress(Data).then((res) => {
            if (res.code == 200) {
              this.dialogFormVisible = false;
              this.DefaultAddress = res.data;
              this.$message.success("added successfully");
            } else {
              this.$message.error("Failed to add");
            }
          });
        } else {
          this.$message.error("Failed to add");
          return false;
        }
      });
    },
    // 删除图片
    DeleteUpload(uuid, imageUrl) {
      DeleteFile(uuid)
        .then((res) => {
          if (res.code === 200) {
            switch (imageUrl) {
              case 1:
                this.imageUrl_1 = "";
                break;
              case 2:
                this.imageUrl_2 = "";
                break;
              case 3:
                this.imageUrl_3 = "";
                break;
              case 4:
                this.imageUrl_4 = "";
                break;
              case 5:
                this.imageUrl_5 = "";
                break;
              case 6:
                this.imageUrl_6 = "";
                break;
              case 7:
                this.imageUrl_7 = "";
                break;
              case 8:
                this.imageUrl_8 = "";
                break;
              case 9:
                this.imageUrl_9 = "";
                break;
              case 10:
                this.imageUrl_10 = "";
                break;
              case 11:
                this.imageUrl_11 = "";
                break;
              case 12:
                this.imageUrl_12 = "";
                break;
              case 13:
                this.imageUrl_13 = "";
                break;
              default:
                break;
            }
            this.$message.success("successfully deleted");
          } else {
            this.$message.error("failed to delete");
          }
        })
        .catch(() => {
          this.$message.error("failed to delete");
        });
    },
    // 上传限制
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error(
          "The size of the uploaded avatar image cannot exceed 2MB!"
        );
      }
      return isLt2M;
    },
    // 上传图片时 图片的ID  和图片
    handleSuccess(response, index) {
      this["imageUrl_" + index] = response.data.url;
      this["imageUuid_" + index] = response.data.uuid;
    },
  },
};
</script>
<style scoped>
.container1 {
  width: 100%;
  height: 60px !important;
  background: #00b0fa;
  top: 0px;
  min-width: 1248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rectangle1 {
  position: absolute;
  width: 100%;
  height: 60px;
  right: 0px;
  top: 0px;
  vertical-align: middle;
}
.bannerImg1 {
  min-width: 1248px;
  height: 60px;
  background: rgba(0, 0, 0, 0.64);
}
.header_inner1 {
  padding: 0 calc(50% - 624px);
  background: rgba(0, 0, 0, 0.64);
  min-width: 1248px;
  height: 60px;
}
.logoImg {
  float: left;
  height: 60px;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 61px;
  color: rgba(64, 255, 153, 1);
  vertical-align: top;
}
.spanDiv {
  width: calc(100% - 145px);
  /* margin-left: calc(11.61% + 144px); */
  display: inline-block;
  height: 60px;
  font-size: 15px;
  vertical-align: middle;
  clear: both;
}
.spanClass {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}
.spanChoosen {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
}
.addCase-header {
  /* position: absolute; */
  width: 100%;
  height: 98px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  text-align: center;
}
.addCase-header-1 {
  position: relative;
  width: 1023px;
  left: calc(50% - 960px / 2);
  height: 98px;
  top: 0px;
}
.addCase-header-item {
  position: relative;
  width: 204px;
  height: 90px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  padding-top: 20px;
  float: left;
  cursor: pointer;
}
.headerActive {
  border-bottom: 3px solid rgba(17, 184, 120, 1);
}
.addCase-header-item-divide-left {
  width: 80px;
  border: 3px solid #f0f0f0;
  position: absolute;
  top: 36px;
  left: 0px;
}
.addCase-header-item-divide-right {
  width: 80px;
  border: 3px solid #f0f0f0;
  position: absolute;
  top: 36px;
}
.addCase-content {
  width: 100%;
  /* min-width: 1248px; */
  height: 1850px;
  display: -webkit-box;
  display: -ms-flexbox;
  background-color: #eef2f6;
  display: flex;
  position: absolute;
}
.patientInfoNew {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.patientInfoNew-content {
  position: absolute;
  width: 960px;
  height: 1671px;
  left: calc(50% - 960px / 2);
  top: 24px;
  background: #ffffff;
  padding: 20px 32px 50px 32px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
}

.patientInfoNew_bottom1 {
  position: fixed !important;
  width: 100%;
  height: 64px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ffffff;
}
.patientInfoNew_bottom_content {
  width: 960px;
  height: 64px;
  margin-left: calc(50% - 960px / 2);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #323232;
  position: relative;
}
.patientInfoNew_bottom_content button {
  cursor: pointer;
}

.button-step {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 259px);
  top: 7px;
  background: #323e4947;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-Staging {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 77px);
  top: 7px;
  border: 1px solid rgba(17, 184, 120, 1);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgb(255, 255, 255);
}
.button-step-colour {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 413px);
  top: 7px;
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-return {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc((50% - 67px) - 413px);
  top: 7px;
  border: 1px solid rgba(17, 184, 120, 1);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgb(255, 255, 255);
}

.el-checkbox-group >>> .el-checkbox-button__inner {
  width: 120px;
  margin: 5px 10px;
  border-left: 1px solid #dcdfe6;
}
.el-textarea >>> .el-textarea__inner {
  width: 600px;
  height: 150px;
}
.el-button--text {
  margin-left: 20px;
}
.el-dialog__wrapper >>> .el-dialog__title {
  font-size: 22px;
}
.el-dialog__wrapper >>> .el-dialog__header {
  padding: 50px 20px 0 190px;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0px 30px 30px 150px;
}
.el-dialog__wrapper >>> .el-dialog__footer {
  text-align: center;
}

.upload-demo >>> .el-upload-dragger {
  width: 200px;
}
.upload-border {
  width: 180px;
  height: 160px;
  margin: 10px 20px;
  border: 2px solid #e8eaec;
  text-align: center;
  text-align-last: center !important;
  border-radius: 4px;
  position: relative;
  background-image: url(https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/Avatar.png);
  background-size: 100% 100%;
}
.image {
  width: 180px;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
}
.upload-p {
  margin: 30px 0;
  line-height: 14px;
  font-size: 16px;
  color: #000;
}
.upload-border >>> .el-upload {
  width: 180px;
  height: 160px;
}

.el-icon-delete-solid {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  background: rgba(353, 255, 255, 0.7);
  color: #8ca37ec7;
  cursor: pointer;
}
.el-form-item >>> .el-form-item__label {
  line-height: 16px;
  padding-top: 4px;
}
h2,
h3,
h4,
h5,
button,
span,
a {
  font-family: "Omnium-Bold";
}
p,
div {
  font-family: "Comfortaa-Bold";
}
</style>