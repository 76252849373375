<template>
  <div>
    <div>
      <div class="content">
        <div style="text-align: center">
          <img
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/cases-3.png"
            class="bannerImg"
          />
        </div>
        <div class="rectangle">
          <Navigationen></Navigationen>
        </div>
      </div>
    </div>
    <div class="home">
      <div style="text-align: center; width: 100%">
        <img
          src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/cases-2.png"
          class="bannerDiv"
          style="width: 100%"
        />
      </div>
      <div class="home_content">
        <div>
          <div
            style="
              float: left;
              display: flex;

              font-size: 15px;
              line-height: 21px;
            "
          >
            <span
              style="
                display: block;
                width: 30px;
                height: 21px;
                margin-top: 34px;
                font-size: 18px;
              "
            >
              Hi</span
            >
            <span
              style="
                display: block;
                height: 34px;
                margin-top: 24px;
                font-size: 24px;
                line-height: 34px;
              "
              >{{ DoctorName }}</span
            >
          </div>
        </div>
        <div class="actionDiv" style="margin-top: 16px">
          <div
            class="action1Div"
            style="
              background-image: url('https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/cases-4.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
            "
          >
            <p class="newCaseSpan">New case</p>

            <div
              @click="Jump('/zh-en/addCase/patientInfoNew')"
              v-if="
                DoctorInformation.permissions.indexOf(
                  'add_complete_medical_case'
                ) !== -1
              "
              style="
                width: 244px;
                height: 40px;
                padding-top: 3px;
                margin-left: 94px;
                background: rgb(255, 255, 255);
                border-radius: 4px;
                cursor: pointer;
              "
            >
              <span
                style="
                  margin-top: 5px;
                  font-weight: 500;
                  font-size: 22px;
                  text-align: center;
                  color: rgba(17, 184, 120, 1);
                "
                >New</span
              >
            </div>
          </div>
          <div @click="Jump('/zh-en/caseList')" class="action2Div">
            <div class="ivu-card ivu-card-bordered">
              <div class="ivu-card-body">
                <span class="blackFont" style="height: 22px; text-align: center"
                  >To be uploaded</span
                >
                <span
                  style="
                    display: block;
                    margin-top: 4px;
                    height: 22px;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    color: rgb(132, 132, 132);
                  "
                  >Cases waiting for you to upload</span
                >
                <span
                  class="numberFont"
                  style="display: block; margin-top: 6px; height: 78px"
                  ><span>{{ CaseNumber.pendingUploadNum }}</span></span
                >
                <img class="CaseHome" src="@/img/CaseHome-1.png" />
              </div>
            </div>
          </div>
          <div
            @click="Jump('/zh-en/caseList')"
            class="action2Div"
            style="margin-left: 32px"
          >
            <div class="ivu-card ivu-card-bordered">
              <div class="ivu-card-body">
                <span class="blackFont" style="height: 22px; text-align: center"
                  >To be reviewed</span
                >
                <span
                  style="
                    display: block;
                    margin-top: 4px;
                    height: 22px;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    color: rgb(132, 132, 132);
                  "
                  >Scheme waiting for your approval</span
                >
                <span
                  class="numberFont"
                  style="display: block; margin-top: 6px; height: 78px"
                  ><span>{{ CaseNumber.underTreatmentNum }}</span></span
                >
                <img src="@/img/CaseHome-2.png" class="CaseHome" />
              </div>
            </div>
          </div>
          <div
            @click="Jump('/zh-en/caseList')"
            class="action2Div"
            style="margin-left: 32px"
          >
            <div class="ivu-card ivu-card-bordered">
              <div class="ivu-card-body">
                <span class="blackFont" style="height: 22px; text-align: center"
                  >In treatment</span
                >
                <span
                  style="
                    display: block;
                    margin-top: 4px;
                    height: 22px;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    color: rgb(132, 132, 132);
                  "
                  >Cases waiting for you</span
                >
                <span
                  class="numberFont"
                  style="display: block; margin-top: 6px; height: 78px"
                  ><span>{{ CaseNumber.pendingReviewNum }}</span></span
                >
                <img src="@/img/CaseHome-3.png" class="CaseHome" />
              </div>
            </div>
          </div>
        </div>
        <div class="statDiv">
          <div style="width: 200px; height: 100%; text-align: left">
            <span
              style="
                height: 22px;
                margin-top: 32px;
                margin-left: 32px;
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: rgb(50, 50, 50);
              "
              >case management</span
            >
            <span
              style="
                height: 22px;
                display: block;
                margin-top: 4px;
                margin-left: 32px;
                font-size: 16px;
                line-height: 22px;
                color: rgb(132, 132, 132);
              "
              >My ongoing cases</span
            >
            <span
              style="
                margin-top: 8px;
                margin-left: 32px;
                width: 121px;
                height: 78px;
                display: block;
                font-weight: 500;
                font-size: 56px;
                line-height: 78px;
                color: rgb(50, 50, 50);
              "
              ><span style="cursor: pointer">{{
                CaseNumber.totalNum
              }}</span></span
            >
            <span
              @click="Jump('/zh-en/caseList')"
              style="
                width: 96px;
                height: 22px;
                margin-left: 32px;
                margin-top: 12px;
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: rgba(17, 184, 120, 1);
                cursor: pointer;
              "
              >View my cases</span
            >
          </div>
          <div
            style="
              margin-top: 32px;
              width: 1px;
              height: 172px;
              border: 1px solid rgb(196, 196, 196);
            "
          ></div>
          <div style="width: 85%; height: 100%">
            <div style="float: left; margin-left: 64px">
              <img
                src="@/img/caselogo-1.png"
                style="margin-top: 32px; width: 40px; height: 40px"
              />
              <span
                style="
                  height: 22px;
                  display: block;
                  font-weight: 500;
                  font-size: 16px;

                  line-height: 22px;
                  color: rgb(50, 50, 50);
                "
                >To be uploaded</span
              >
              <div>
                <span
                  style="
                    width: 31px;
                    height: 17px;
                    display: block;

                    font-size: 12px;
                    line-height: 17px;
                    color: rgb(132, 132, 132);
                  "
                  >Step1</span
                >
              </div>
              <div
                style="
                  float: left;
                  padding: 2px 0px;
                  margin-top: 11px;
                  width: 86px;
                  height: 60px;
                  background: rgb(216, 227, 246);
                  border-radius: 8px;
                  text-align: center;
                  display: table-cell;
                  vertical-align: middle;
                "
              >
                <span
                  style="
                    display: block;
                    width: 86px;
                    height: 60px;

                    font-weight: 500;
                    font-size: 32px;
                    line-height: 60px;
                    text-align: center;
                    color: rgba(17, 184, 120, 1);
                  "
                  ><span style="cursor: pointer">{{
                    CaseNumber.pendingUploadNum
                  }}</span></span
                >
              </div>
              <span
                style="
                  margin-top: 75px;
                  width: 60px;
                  height: 17px;
                  display: block;

                  font-size: 12px;
                  line-height: 17px;
                  text-align: center;
                  color: rgba(17, 184, 120, 1);
                "
                >Approximately{{ CaseNumber.pendingUploadRate }}</span
              >
            </div>
            <div style="float: left; margin-left: 108px">
              <img
                src="@/img/caselogo-2.png"
                style="margin-top: 32px; width: 40px; height: 40px"
              />
              <span
                style="
                  height: 22px;
                  display: block;
                  font-weight: 500;
                  font-size: 16px;

                  line-height: 22px;
                  color: rgb(50, 50, 50);
                "
                >To be reviewed</span
              >
              <div>
                <span
                  style="
                    width: 31px;
                    height: 17px;
                    display: block;

                    font-size: 12px;
                    line-height: 17px;
                    color: rgb(132, 132, 132);
                  "
                  >Step2</span
                >
              </div>
              <div
                style="
                  float: left;
                  padding: 2px 0px;
                  margin-top: 11px;
                  width: 86px;
                  height: 60px;
                  background: rgb(216, 227, 246);
                  border-radius: 8px;
                  text-align: center;
                  display: table-cell;
                  vertical-align: middle;
                "
              >
                <span
                  style="
                    display: block;
                    width: 86px;
                    height: 60px;

                    font-weight: 500;
                    font-size: 32px;
                    line-height: 60px;
                    text-align: center;
                    color: rgba(17, 184, 120, 1);
                  "
                  ><span style="cursor: pointer">{{
                    CaseNumber.awaitingReceptionNum
                  }}</span></span
                >
              </div>
              <span
                style="
                  margin-top: 75px;
                  width: 60px;
                  height: 17px;
                  display: block;

                  font-size: 12px;
                  line-height: 17px;
                  text-align: center;
                  color: rgba(17, 184, 120, 1);
                "
                >Approximately{{ CaseNumber.awaitingReceptionRate }}</span
              >
            </div>
            <div style="float: left; margin-left: 108px">
              <img
                src="@/img/caselogo-3.png"
                style="margin-top: 32px; width: 40px; height: 40px"
              />
              <span
                style="
                  height: 22px;
                  display: block;
                  font-weight: 500;
                  font-size: 16px;

                  line-height: 22px;
                  color: rgb(50, 50, 50);
                "
              >
                In design</span
              >
              <div>
                <span
                  style="
                    width: 31px;
                    height: 17px;
                    display: block;

                    font-size: 12px;
                    line-height: 17px;
                    color: rgb(132, 132, 132);
                  "
                  >Step3</span
                >
              </div>
              <div
                style="
                  float: left;
                  padding: 2px 0px;
                  margin-top: 11px;
                  width: 86px;
                  height: 60px;
                  background: rgb(216, 227, 246);
                  border-radius: 8px;
                  text-align: center;
                  display: table-cell;
                  vertical-align: middle;
                "
              >
                <span
                  style="
                    display: block;
                    width: 86px;
                    height: 60px;

                    font-weight: 500;
                    font-size: 32px;
                    line-height: 60px;
                    text-align: center;
                    color: rgba(17, 184, 120, 1);
                  "
                  ><span style="cursor: pointer">{{
                    CaseNumber.designProgressNum
                  }}</span></span
                >
              </div>
              <span
                style="
                  margin-top: 75px;
                  width: 60px;
                  height: 17px;
                  display: block;

                  font-size: 12px;
                  line-height: 17px;
                  text-align: center;
                  color: rgba(17, 184, 120, 1);
                "
                >Approximately{{ CaseNumber.designProgressRate }}</span
              >
            </div>
            <div style="float: left; margin-left: 108px">
              <img
                src="@/img/caselogo-4.png"
                style="margin-top: 32px; width: 40px; height: 40px"
              />
              <span
                style="
                  height: 22px;
                  display: block;
                  font-weight: 500;
                  font-size: 16px;

                  line-height: 22px;
                  color: rgb(50, 50, 50);
                "
              >
                To be reviewed</span
              >
              <div>
                <span
                  style="
                    width: 31px;
                    height: 17px;
                    display: block;

                    font-size: 12px;
                    line-height: 17px;
                    color: rgb(132, 132, 132);
                  "
                  >Step4</span
                >
              </div>
              <div
                style="
                  float: left;
                  padding: 2px 0px;
                  margin-top: 11px;
                  width: 86px;
                  height: 60px;
                  background: rgb(216, 227, 246);
                  border-radius: 8px;
                  text-align: center;
                  display: table-cell;
                  vertical-align: middle;
                "
              >
                <span
                  style="
                    display: block;
                    width: 86px;
                    height: 60px;

                    font-weight: 500;
                    font-size: 32px;
                    line-height: 60px;
                    text-align: center;
                    color: rgba(17, 184, 120, 1);
                  "
                  ><span style="cursor: pointer">{{
                    CaseNumber.pendingReviewNum
                  }}</span></span
                >
              </div>
              <span
                style="
                  margin-top: 75px;
                  width: 60px;
                  height: 17px;
                  display: block;

                  font-size: 12px;
                  line-height: 17px;
                  text-align: center;
                  color: rgba(17, 184, 120, 1);
                "
                >Approximately{{ CaseNumber.pendingReviewRate }}</span
              >
            </div>
            <div style="float: left; margin-left: 108px">
              <img
                src="@/img/caselogo-5.png"
                style="margin-top: 32px; width: 40px; height: 40px"
              />
              <span
                style="
                  height: 22px;
                  display: block;
                  font-weight: 500;
                  font-size: 16px;

                  line-height: 22px;
                  color: rgb(50, 50, 50);
                "
              >
                In treatment</span
              >
              <div>
                <span
                  style="
                    width: 31px;
                    height: 17px;
                    display: block;

                    font-size: 12px;
                    line-height: 17px;
                    color: rgb(132, 132, 132);
                  "
                  >Step5</span
                >
              </div>
              <div
                style="
                  float: left;
                  padding: 2px 0px;
                  margin-top: 11px;
                  width: 86px;
                  height: 60px;
                  background: rgb(216, 227, 246);
                  border-radius: 8px;
                  text-align: center;
                  display: table-cell;
                  vertical-align: middle;
                "
              >
                <span
                  style="
                    display: block;
                    width: 86px;
                    height: 60px;

                    font-weight: 500;
                    font-size: 32px;
                    line-height: 60px;
                    text-align: center;
                    color: rgba(17, 184, 120, 1);
                  "
                  ><span style="cursor: pointer">{{
                    CaseNumber.underTreatmentNum
                  }}</span></span
                >
              </div>
              <span
                style="
                  margin-top: 75px;
                  width: 60px;
                  height: 17px;
                  display: block;

                  font-size: 12px;
                  line-height: 17px;
                  text-align: center;
                  color: rgba(17, 184, 120, 1);
                "
                >Approximately{{ CaseNumber.underTreatmentRate }}</span
              >
            </div>
            <div>
              <div
                style="
                  width: 434px;
                  height: 4px;
                  margin-left: 65px;
                  margin-top: 156px;
                  background: rgb(216, 227, 247);
                "
              ></div>
              <div
                style="
                  width: 4px;
                  height: 4px;
                  margin-left: 561px;
                  background: rgb(216, 227, 246);
                  margin-top: -4px;
                "
              ></div>
              <div
                style="
                  width: 16px;
                  height: 4px;
                  margin-left: 573px;
                  background: rgb(216, 227, 246);
                  margin-top: -4px;
                "
              ></div>
              <div
                style="
                  width: 4px;
                  height: 4px;
                  margin-left: 597px;
                  background: rgb(216, 227, 246);
                  margin-top: -4px;
                "
              ></div>
              <div
                style="
                  width: 16px;
                  height: 4px;
                  margin-left: 609px;
                  background: rgb(216, 227, 246);
                  margin-top: -4px;
                "
              ></div>
              <div
                style="
                  width: 4px;
                  height: 4px;
                  margin-left: 633px;
                  background: rgb(216, 227, 246);
                  margin-top: -4px;
                "
              ></div>
              <div
                style="
                  width: 16px;
                  height: 4px;
                  margin-left: 645px;
                  background: rgb(216, 227, 246);
                  margin-top: -4px;
                "
              ></div>
              <div
                style="
                  width: 4px;
                  height: 4px;
                  margin-left: 669px;
                  background: rgb(216, 227, 246);
                  margin-top: -4px;
                "
              ></div>
              <div
                style="
                  width: 260px;
                  height: 4px;
                  margin-left: 678px;
                  background: rgb(216, 227, 247);
                  margin-top: -4px;
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottomen></Bottomen>
  </div>
</template>

<script>
import Navigationen from "@/components/Navigationen.vue";
import { statistics } from "@/api/outpatient";
import Bottomen from "@/components/Bottomen.vue";

export default {
  metaInfo: {
    link: [
      {
        rel: "canonical",
        href: "https://mybeame.com/zh-hk/",
      },
      {
        rel: "alternate",
        href: "https://mybeame.com/zh-hk/",
        hrefLang: "x-default",
      },
    ],
  },
  components: {
    Bottomen,
    Navigationen,
  },

  data() {
    return {
      uuid: "",
      CaseNumber: "",
      DoctorName: "",
      DoctorInformation: "",
    };
  },
  created() {
    localStorage.removeItem("DoctorID"); //清除本地储存
    localStorage.removeItem("ProgrammeID"); //清除本地储存
    localStorage.removeItem("PatientID"); //清除本地储存
    localStorage.removeItem("caseDetail"); //清除本地储存
    this.DoctorName = JSON.parse(localStorage.getItem("DoctorName"));
    if (
      localStorage.getItem("uuid") != null &&
      localStorage.getItem("uuid") != ""
    ) {
      this.uuid = JSON.parse(localStorage.getItem("uuid"));
      statistics(this.uuid).then((res) => {
        this.CaseNumber = res.data;
      });
    }
    // 获取个人信息
    this.DoctorInformation = JSON.parse(
      localStorage.getItem("DoctorInformation")
    );
  },
  methods: {
    Jump(route) {
      this.$router.push(route);
    },
  },
};
</script>
<style scoped>
.content {
  width: 100%;
  height: 140px;
  min-width: 1248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bannerImg {
  width: 100%;
}
.rectangle {
  position: absolute;
  width: 100%;
  height: 60px;
  right: 0px;
  top: 80px;
  vertical-align: middle;
}

.home {
  background: #e5e5e5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 1248px;
}
.home_content {
  padding: 0px calc(50% - 1248px / 2);
  padding-bottom: 100px;
}
.actionDiv {
  height: 244px;
  width: 1248px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.action1Div {
  /* background: url(play); */
  height: 248px;
  width: 432px;
  /* background-color: #2961CB; */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}
.newCaseSpan {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-top: 65px;
  height: 28px;
  color: #ffffff;
}

.numberFont {
  font-weight: 500;
  font-size: 56px;
  line-height: 78px;
  text-align: center;
  color: #000000;
  text-shadow: 1px 4px 4px rgba(0, 0, 0, 0.17);
}
.CaseHome {
  width: 236px;
  height: 100px;
  border-radius: 4px;
  transform: translate(-14px, 3px);
  opacity: 0.8;
}

.action2Div {
  height: 248px;
  width: 240px;
  margin-left: 10px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}
.action2Div .ivu-card-bordered {
  border: none !important;
}

.ivu-card {
  display: block;
  background: #fff;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.ivu-card-body {
  padding: 16px 16px 0 16px;
}
.statDiv {
  height: 244px;
  width: 1248px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.el-dropdown-menu {
  top: 125px !important;
  left: 1365px !important;
}
.el-dropdown-menu >>> .popper__arrow {
  display: none;
}
h2,
h3,
h4,
h5,
button,
span,
a {
  font-family: "Omnium-Bold";
}
p,
div {
  font-family: "Comfortaa-Bold";
}
</style>