<template>
  <div>
    <div>
      <div class="container1">
        <div class="rectangle1">
          <Navigationen></Navigationen>
        </div>
      </div>
    </div>
    <div class="addCase-header">
      <div class="addCase-header-1">
        <div class="addCase-header-item">
          <span
            class="addCase-header-item-divide-left"
            style="display: none"
          ></span>
          <img data-v-893d091a="" src="@/img/patient-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Patient information</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img data-v-893d091a="" src="@/img/diagnosis-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Diagnostic information</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/information-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Treatment plan</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/information-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div></div>
        </div>
        <div class="addCase-header-item headerActive">
          <span class="addCase-header-item-divide-left"></span>
          <img style="width: 40px" src="@/img/CaseProcess-4.png" />
          <span
            class="addCase-header-item-divide-right"
            style="display: none"
          ></span>
          <div>Data upload</div>
        </div>
      </div>
    </div>
    <div class="addCase-content">
      <div class="patientInfoNew">
        <div class="patientInfoNew-content">
          <p
            style="
              font-size: 20px;
              line-height: 160%;
              display: flex;
              align-items: center;
              color: rgb(0, 0, 0);
            "
          >
            5.Data upload
          </p>
          <p
            style="
              height: 42px;
              font-size: 13px;
              line-height: 160%;
              color: rgb(85, 85, 85);
              margin-top: 8px;
            "
          >
            You can add up to two lines of filling help or notes here. If there
            is no setting, it will be blank. The text space here is designed to
            effectively solve the matters needing attention in data filling and
            reduce errors.
          </p>
          <img
            src="@/img/beame.png"
            style="
              width: 400px;
              height: 64px;
              position: absolute;
              top: 20px;
              right: 20px;
            "
          />
          <div class="">
            <div>
              <div ref="viewer-container"></div>
            </div>
            <el-form>
              <el-form-item>
                <p
                  style="margin-bottom: 0px; line-height: 34px; font-size: 16px"
                >
                  1.Dental data upload
                </p>
                <p style="font-size: 12px; margin-top: 0; line-height: 14px">
                  Only. stl files can be uploaded
                </p>
                <div style="display: flex">
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 1)"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_1"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Digital model <br />
                        of upper jaw <br />
                        <br />
                        + upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_1" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_1, 1)"
                      ></i>
                      <img class="image" :src="imageUrl_1" />
                    </div>
                  </div>
                  <div class="upload-border">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      :action="uploadUrl"
                      :on-success="(response) => handleSuccess(response, 2)"
                      :show-file-list="false"
                      :headers="myHeaders"
                      :data="photo_2"
                    >
                      <p class="upload-p" size="small" type="primary">
                        Mandibular<br />digital model <br />
                        <br />
                        + upload
                      </p>
                    </el-upload>
                    <div v-if="imageUrl_2" style="width: 100%; font-size: 32px">
                      <i
                        class="el-icon-delete-solid"
                        @click="DeleteUpload(imageUuid_2, 2)"
                      ></i>
                      <img class="image" :src="imageUrl_2" />
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <p
                  style="margin-bottom: 0px; line-height: 34px; font-size: 16px"
                >
                  2.courier number
                </p>
                <p style="font-size: 12px; margin-top: 0; line-height: 14px">
                  If you haven't uploaded it Stl files can be sent to us by
                  express. After receiving the dental model you sent, we will
                  handle the case for you as soon as possible
                </p>
                <el-select v-model="value" :placeholder="value">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option> </el-select
                ><el-input
                  v-model="input"
                  placeholder="Please fill in the express bill number"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <p
                  style="margin-bottom: 0px; line-height: 34px; font-size: 16px"
                >
                  3.Oral scanning/CT file upload
                </p>
                <p
                  style="font-size: 12px; margin: 0 0 25px 0; line-height: 14px"
                >
                  The file must be compressed before the oral scan/ct file is
                  uploaded, or the upload may fail
                </p>
                <div class="upload-border">
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="uploadUrl"
                    :on-success="(response) => handleSuccess(response, 3)"
                    :show-file-list="false"
                    :headers="myHeaders"
                    :data="photo_3"
                  >
                    <p class="upload-p" size="small" type="primary">
                      CT + upload
                    </p>
                  </el-upload>
                  <div v-if="imageUrl_3" style="width: 100%; font-size: 32px">
                    <i
                      class="el-icon-delete-solid"
                      @click="DeleteUpload(imageUuid_3, 3)"
                    ></i>
                    <img class="image" :src="imageUrl_3" />
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div class="patientInfoNew_bottom1">
          <div class="patientInfoNew_bottom_content">
            <button
              @click="Jump('/zh-en/Home')"
              type="button"
              class="button-return"
            >
              <span>return</span>
            </button>
            <button type="button" class="button-save">
              <span>Staging</span>
            </button>
            <button
              @click="Jump('/zh-en/addCase/transparentMarkerNew')"
              type="button"
              class="button-step-upper"
            >
              <span>previous step</span>
            </button>
            <button @click="SubmiTo()" type="button" class="button-step-colour">
              <span>Submit case</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Navigationen from "@/components/Navigationen.vue";

import { material, DeleteFile, getRecord } from "@/api/outpatient";
export default {
  metaInfo: {
    link: [],
  },
  components: { Navigationen },
  data() {
    return {
      options: [
        {
          value: "1",
          label: "顺丰",
        },
        {
          value: "2",
          label: "德邦",
        },
        {
          value: "3",
          label: "EMS",
        },
        {
          value: "4",
          label: "圆通",
        },
        {
          value: "5",
          label: "申通",
        },
        {
          value: "6",
          label: "中通",
        },
        {
          value: "7",
          label: "韵达",
        },
      ],
      value: "1",
      photo_1: {},
      uploadUrl: "https://cases.mybeame.info/api/file/upload",
      // uploadUrl: "http://172.18.80.32/api/file/upload",
      myHeaders: {},
      imageUrl_1: "",
      imageUrl_2: "",
      imageUrl_3: "",
      imageUuid_1: "",
      imageUuid_2: "",
      imageUuid_3: "",
      photo_1: {},
      photo_2: {},
      photo_3: {},
      input: "",
      PatientID: "",
    };
  },
  created() {
    // debugger;
    var data = JSON.parse(sessionStorage.getItem("token"));
    this.myHeaders.Authorization = "Bearer " + data;

    // 获取患者资料
    if (
      localStorage.getItem("PatientID") != null &&
      localStorage.getItem("PatientID") != null
    ) {
      this.PatientID = JSON.parse(localStorage.getItem("PatientID"));
      getRecord(this.PatientID).then((res) => {
        const data = res.data;
        if (data.maxilla_model_file_url) {
          this.imageUrl_1 = data.maxilla_model_file_url;
        }
        if (data.mandibular_model_file_url) {
          this.imageUrl_2 = data.mandibular_model_file_url;
        }
        if (data.ct_file_url) {
          this.imageUrl_3 = data.ct_file_url;
        }
        if (data.maxilla_model_file) {
          this.imageUuid_1 = data.maxilla_model_file;
        }
        if (data.mandibular_model_file) {
          this.imageUuid_2 = data.mandibular_model_file;
        }
        if (data.ct_file) {
          this.imageUuid_3 = data.ct_file;
        }
        if (data.express_id) {
          this.input = data.express_id;
        }
        if (data.express_type) {
          this.value = String(data.express_type);
        }
      });
    }
  },
  mounted() {
    this.photo_1 = {
      uuid: JSON.parse(localStorage.getItem("PatientID")),
      type: "14",
    };
    this.photo_2 = {
      uuid: JSON.parse(localStorage.getItem("PatientID")),
      type: "15",
    };
    this.photo_3 = {
      uuid: JSON.parse(localStorage.getItem("PatientID")),
      type: "16",
    };
  },
  methods: {
    // 删除图片
    DeleteUpload(uuid, imageUrl) {
      DeleteFile(uuid)
        .then((res) => {
          if (res.code === 200) {
            switch (imageUrl) {
              case 1:
                this.imageUrl_1 = "";
                break;
              case 2:
                this.imageUrl_2 = "";
                break;
              case 3:
                this.imageUrl_3 = "";
                break;
              default:
                break;
            }
            this.$message.success("successfully deleted");
          } else {
            this.$message.error("failed to delete");
          }
        })
        .catch(() => {
          this.$message.error("failed to delete");
        });
    },
    // 跳转
    Jump(route) {
      this.$router.push(route);
    },
    // beforeUpload(file) {
    //   const isJPG = file.type === "image/jpeg";
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     this.$message.error("上传头像图片大小不能超过 2MB!");
    //   }
    //   return isLt2M;
    // },
    // 上传的图片 和ID
    handleSuccess(response, index) {
      this["imageUrl_" + index] = response.data.url;
      this["imageUuid_" + index] = response.data.uuid;
    },
    // 发送资料
    SubmiTo() {
      var data = {
        uuid: JSON.parse(localStorage.getItem("PatientID")),
        maxilla_model_file: this.imageUuid_1,
        mandibular_model_file: this.imageUuid_2,
        ct_file: this.imageUuid_3,
        express_type: this.value,
        express_id: this.input,
      };
      material(data).then((res) => {
        if (res.code == 200) {
          this.$router.push("/zh-en/caseList");
        }
      });
    },
  },
};
</script>
  <style scoped>
.container1 {
  width: 100%;
  height: 60px !important;
  background: #00b0fa;
  top: 0px;
  min-width: 1248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rectangle1 {
  position: absolute;
  width: 100%;
  height: 60px;
  right: 0px;
  top: 0px;
  vertical-align: middle;
}
.bannerImg1 {
  min-width: 1248px;
  height: 60px;
  background: rgba(0, 0, 0, 0.64);
}
.header_inner1 {
  padding: 0 calc(50% - 624px);
  background: rgba(0, 0, 0, 0.64);
  min-width: 1248px;
  height: 60px;
}
.logoImg {
  /* height: 40px; */
  /* margin-left: 11.61%; */
  float: left;
  height: 60px;
}
.spanDiv {
  width: calc(100% - 145px);
  /* margin-left: calc(11.61% + 144px); */
  display: inline-block;
  height: 60px;
  font-size: 15px;
  vertical-align: middle;
  clear: both;
}
.spanClass {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}
.spanChoosen {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
}
.addCase-header {
  /* position: absolute; */
  width: 100%;
  height: 98px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  text-align: center;
}
.addCase-header-1 {
  position: relative;
  width: 1023px;
  left: calc(50% - 960px / 2);
  height: 98px;
  top: 0px;
}
.addCase-header-item {
  position: relative;
  width: 204px;
  height: 90px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  padding-top: 20px;
  float: left;
  cursor: pointer;
}
.headerActive {
  border-bottom: 3px solid rgba(17, 184, 120, 1);
}
.addCase-header-item-divide-left {
  width: 80px;
  border: 3px solid #f0f0f0;
  position: absolute;
  top: 36px;
  left: 0px;
}
.addCase-header-item-divide-right {
  width: 80px;
  border: 3px solid #f0f0f0;
  position: absolute;
  top: 36px;
}
.addCase-content {
  width: 100%;
  /* min-width: 1248px; */
  height: 1100px;
  display: -webkit-box;
  display: -ms-flexbox;
  background-color: #eef2f6;
  display: flex;
  position: absolute;
}
.patientInfoNew {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.patientInfoNew-content {
  position: absolute;
  width: 960px;
  height: 900px;
  left: calc(50% - 960px / 2);
  top: 24px;
  background: #ffffff;
  padding: 20px 32px 50px 32px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
}

.patientInfoNew_bottom1 {
  position: fixed !important;
  width: 100%;
  height: 64px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ffffff;
}
.patientInfoNew_bottom_content {
  width: 960px;
  height: 64px;
  margin-left: calc(50% - 960px / 2);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #323232;
  position: relative;
}
.patientInfoNew_bottom_content button {
  cursor: pointer;
}
.button-step-below {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 413px);
  top: 7px;
  background: rgb(217, 230, 255);

  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-step-upper {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 250px);
  top: 7px;
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-save {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 77px);
  top: 7px;
  border: 1px solid rgba(17, 184, 120, 1);
  box-sizing: border-box;
  border-radius: 4px;
  color: rgba(17, 184, 120, 1);
  background: rgb(255, 255, 255);
}
.button-step-colour {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 450px);
  top: 7px;
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-return {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc((50% - 67px) - 413px);
  top: 7px;
  border: 1px solid rgba(17, 184, 120, 1);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgb(255, 255, 255);
}
.upload-demo >>> .el-upload-dragger {
  width: 200px;
}
.upload-demo {
  width: 180px;
  height: 160px;
  /* border-bottom: 1px dashed #d9d9d9; */
  color: #00b0fa;
}
.upload-border >>> .el-upload {
  width: 180px;
  height: 160px;
}
.el-input {
  width: 200px;
  margin-left: 20px;
}

.upload-border {
  width: 180px;
  height: 160px;
  margin: 10px 20px;
  border: 2px solid #e8eaec;
  text-align: center;
  text-align-last: center !important;
  border-radius: 4px;
  position: relative;
  background-image: url(https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/Avatar.png);
  background-size: 100%;
}
.image {
  width: 180px;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.el-icon-delete-solid {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  background: rgba(353, 255, 255, 0.7);
  color: #8ca37ec7;
  cursor: pointer;
}
.upload-p {
  margin: 30px 0;
  line-height: 20px;
  font-size: 16px;
  color: #000;
}
h2,
h3,
h4,
h5,
button,
span,
a {
  font-family: "Omnium-Bold";
}
p,
div {
  font-family: "Comfortaa-Bold";
}
</style>