<template>
  <div>
    <div class="content">
      <div style="text-align: center">
        <img
          src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/cases-3.png"
          class="bannerImg"
        />
      </div>
      <div class="rectangle">
        <div class="header_inner">
          <span class="logoImg">be@me</span>
          <div class="spanDiv">
            <div style="float: left; width: 260px">
              <span @click="Jump('/Home')" class="spanClass">工作臺</span>
              <span
                @click="Jump('/caseList')"
                class="spanClass"
                style="margin-left: 41px"
                >病例</span
              >
              <span
                @click="Jump('/patientListNew')"
                class="spanChoosen"
                style="margin-left: 50px"
                >患者</span
              >
            </div>
            <div
              style="float: right; width: calc(100% - 270px); text-align: right"
            >
              <input
                v-model="input"
                placeholder="搜索患者姓名/病例号"
                type="text"
                @input="search()"
                class="inputClass"
                style="margin-right: 50px"
              />
              <div style="height: 60px; width: 220px; float: right">
                <span title="姓名" class="namespan">{{
                  DoctorInformation.name
                }}</span>

                <img
                  @click="gotoThisPath('/zh-en')"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAMAAADypuvZAAAAP1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////9Du/pqAAAAFHRSTlMAYH/fPzAQ758fII9vv4DPr0+gUKu28wAAAAGpSURBVEjHnZbdloMgDIQDAUP5Ubs77/+sW7urURcVOzc9gX5OguYQqsjnMDBe4pKypwa5JNiIg79CCgAJ+fnoXtHD5cAABnOBiHW7ZCeO4wHSpQnpKjuGgdTVmMiAIjvZupkX8EnFkSH/tg0Q1KaW/AiYnQ9gNTpM0W/MRZkzSuLKmjFW8nX7tS+wlpA0qEP68LQkB0RqgcjrIsPSBaRllXmbqRXq5G91gGmE1MqrkVkpAGkdb60CwgzhRGqV3sfg70AO8pudQukxKwPPJUgKkbxMMsIKsvWDsCsoIFNCvgdNLgXuHvSNgRiPe1AEk4aNUAfch+hzqLsNfXYQA54KHUshh0IB+R6UkXafUbGzRiAsQdl9Rh5yo6a5K0Q3mXMdyszTj/ZsgqXWdl961kG6Vkh7tsC2QwYDkVq1QQxDpFZNUA9e3jF8GxSB+NkFoEFogcbNw6Ogv4Z6SNzdIf0V1Gvp+p/x/KL+AkxtJIjHjGeIrw8f/ZFND3A8GnPYVBHRMadiVpnU3IQUR8cyAyYuuzg5xO8cBKeITmobSVLkjMupvEkeQnUc/QEdFijuTK/OWgAAAABJRU5ErkJggg=="
                  style="
                    width: 24px;
                    height: 24px;
                    transform: translateY(13px);
                    cursor: pointer;
                  "
                />
                <span style="margin-left: 22px">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <img
                        v-if="
                          DoctorInformation != null && DoctorInformation != ''
                        "
                        :src="DoctorInformation.avatar"
                        style="
                          width: 30px;
                          height: 30px;
                          transform: translateY(16px);
                        "
                      />
                      <img
                        v-else
                        src="http://static.file.eset3d.com/avatar.png"
                        style="
                          width: 30px;
                          height: 30px;
                          transform: translateY(16px);
                        "
                      />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        ><p
                          @click="Jump('/personalcenter')"
                          style="margin: 0px"
                        >
                          個人中心
                        </p></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><p @click="LogOff()" style="margin: 0px">
                          註銷
                        </p></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; background-color: #eef2f6; padding-bottom: 50px">
      <div class="container-2">
        <div class="CationBox">
          <div class="row-2">
            <div class="name">性别：</div>
            <div class="content-3">
              <div
                v-for="(item, index) in PlatformList"
                :key="index"
                @click="ChooseGender(item.Value)"
                :class="['item', PlatformVar == item.Value ? 'actvCss' : '']"
              >
                {{ item.name }}
              </div>
            </div>
          </div>

          <!-- <div class="name">类型：</div>
            <div class="content">
              <div
                v-for="(item, index) in TypeList"
                :key="index"
                @click="TypeVar = item"
                :class="['item', TypeVar == item ? 'actvCss' : '']"
              >
                {{ item }}
              </div>
            </div> -->
          <div>
            <span style="float: left; font-weight: 600">分類：</span>
            <button
              class="all"
              @click="AllButton(1)"
              :class="['all', TypeVar == 'all' ? 'allcolor' : '']"
            >
              全部
            </button>
            <el-form ref="form" :model="form" label-width="115px">
              <el-form-item>
                <el-checkbox-group
                  size="small"
                  v-model="form.type"
                  @change="handleTypeChange"
                >
                  <el-checkbox-button label="1" value="type"
                    >擁擠</el-checkbox-button
                  >
                  <el-checkbox-button label="2" name="type"
                    >稀疏</el-checkbox-button
                  >
                  <el-checkbox-button label="3" name="type"
                    >深覆合</el-checkbox-button
                  >
                  <el-checkbox-button label="4" name="type"
                    >深覆盖</el-checkbox-button
                  >
                  <el-checkbox-button label="5" name="type"
                    >II類1分類</el-checkbox-button
                  >
                  <el-checkbox-button label="6" name="type"
                    >II類2分類</el-checkbox-button
                  >
                  <el-checkbox-button label="7" name="type"
                    >III類</el-checkbox-button
                  >
                  <el-checkbox-button label="8" name="type"
                    >對刃</el-checkbox-button
                  >
                  <el-checkbox-button label="9" name="type"
                    >開合</el-checkbox-button
                  >
                  <el-checkbox-button label="10" name="type"
                    >前牙反合</el-checkbox-button
                  >
                  <el-checkbox-button label="11" name="type"
                    >後牙反合</el-checkbox-button
                  >
                  <el-checkbox-button label="12" name="type"
                    >牙弓狭窄</el-checkbox-button
                  >
                  <el-checkbox-button label="13" name="type"
                    >牙周紅腫</el-checkbox-button
                  >
                  <el-checkbox-button label="14" name="type"
                    >口腔炎症</el-checkbox-button
                  >
                  <el-checkbox-button label="15" name="type"
                    >骨質疏鬆</el-checkbox-button
                  >
                  <el-checkbox-button label="16" name="type"
                    >顳頜關節紊亂</el-checkbox-button
                  >
                  <el-checkbox-button label="17" name="type"
                    >前突</el-checkbox-button
                  >
                  <el-checkbox-button label="18" name="type"
                    >笑線不調</el-checkbox-button
                  >
                  <el-checkbox-button label="19" name="type"
                    >畸形牙</el-checkbox-button
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="content-2">
          <div>
            <div class="content-titleFont">
              <ul style="width: 100%">
                <li
                  style="display: flex; list-style: none; pointer-events: none"
                >
                  <div
                    style="width: 20%; display: flex; justify-content: center"
                  >
                    <span class="titleFont">患者</span>
                  </div>

                  <div style="width: 35%">
                    <span class="titleFont">備註</span>
                  </div>
                  <div style="width: 30%">
                    <span title="" class="titleFont">分類</span>
                  </div>
                  <div style="width: 10%">
                    <span title="" class="titleFont">狀態</span>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="testData.length">
              <div
                v-for="(item, index) in testData"
                :key="index"
                class="dentist-office"
                @click="PatientData(item.uuid)"
              >
                <div class="blog-date-img-wrapper">
                  <img
                    v-if="
                      item.frontal_avatar != null && item.frontal_avatar != ''
                    "
                    class="dentist-img"
                    :src="item.frontal_avatar"
                    alt=""
                  />
                  <img
                    v-else
                    class="dentist-img"
                    src="../img/default.png"
                    alt=""
                  />
                  <div class="dentist-data">
                    <p class="dentist-name">{{ item.name }}</p>
                    <!-- <br /> -->
                    <span v-if="(item.gender = 1)" class="dentist-gender"
                      >男</span
                    >
                    <span v-else class="dentist-gender">女</span>
                    &nbsp;<span class="dentist-age">{{ item.age }}岁</span>
                  </div>
                </div>

                <div :title="item.remarks" class="dentist-notes">
                  {{ item.remarks }}
                </div>

                <div class="dentist-state">
                  {{ item.clinical_situation }}
                </div>
                <div class="dentist-state">
                  {{ item.progress }}
                </div>
              </div>
            </div>
            <div v-else class="nullCss">暫無相關案例~</div>
          </div>
          <div class="pagination">
            <el-pagination
              :total="total"
              background
              layout="total,  prev, pager, next, jumper"
              :page-size="pageSize"
              @current-change="handlePageChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
  <script>
import Bottom from "@/components/Bottom.vue";
import { getList } from "@/api/outpatient";

export default {
  metaInfo: {
    link: [],
  },
  components: {
    Bottom,
  },
  data() {
    return {
      form: {
        type: [],
      },
      input: "",
      SearchFor: "全部",
      PlatformVar: "all", // 表示当前选中的性别
      PlatformList: [
        { Value: "all", name: "全部" },
        { Value: "1", name: "男" },
        { Value: "2", name: "女" },
      ], // 性别
      TypeVar: "all", // 表示当前默认的全部分类
      testData: [],
      currentPage: 1, // 当前页码
      pageSize: 3, // 每页显示的数据量
      DoctorInformation: "",
      total: 0,
      clinical_situation: [],
    };
  },
  created() {
    // 获取个人信息
    let DoctorInformation = JSON.parse(
      localStorage.getItem("DoctorInformation")
    );
    for (let i = 0; i < DoctorInformation.length; i++) {
      console.log(DoctorInformation[i].name, DoctorInformation[i].age);
    }
    this.DoctorInformation = DoctorInformation;
    if (
      localStorage.getItem("uuid") != null &&
      localStorage.getItem("uuid") != ""
    ) {
      this.uuid = JSON.parse(localStorage.getItem("uuid"));
      // 获取患者列表
      var data = {
        uuid: this.uuid,
        gender: this.PlatformVar,
        clinical_situation: this.TypeVar,
      };
      getList(data).then((res) => {
        this.testData = res.data.list;
        this.total = res.data.total;
      });
    }
  },
  computed: {
    // ComDataList() {
    //   // debugger;
    //   console.log(this.PlatformVar);
    //   return this.testData.filter((item) => {
    //     if (this.input == "") {
    //       this.SearchFor = "all";
    //     }
    //     if (
    //       (this.PlatformVar == "all" || this.PlatformVar == item.gender) &&
    //       (this.TypeVar == "all" || this.TypeVar == item.classification) &&
    //       (this.SearchFor == "all" ||
    //         this.SearchFor == item.name ||
    //         this.SearchFor == item.number)
    //     ) {
    //       return item; // 把符合条件的数据返回,模板中通过this.ComDataList可直接动态读取数据
    //     }
    //   });
    // },
    // totalDataCount() {
    //   return this.testData.length; // 总数据量
    // },
    // currentPageData() {
    //   const startIndex = (this.currentPage - 1) * this.pageSize; // 当前页起始索引
    //   const endIndex = startIndex + this.pageSize; // 当前页结束索引
    //   return this.testData.slice(startIndex, endIndex); // 当前页数据
    // },
  },

  mounted() {
    var url = this.$route.name;
    if (url == "Home" || url == "caseList" || url == "patientListNew") {
      localStorage.setItem("route", JSON.stringify(this.$route.name));
      this.route = JSON.parse(localStorage.getItem("route"));
    }
  },
  methods: {
    //选择性别时获取数据
    ChooseGender(data) {
      this.PlatformVar = data;
      // 获取患者列表
      var data = {
        uuid: this.uuid,
        page: this.currentPage,
        gender: this.PlatformVar,
        clinical_situation: this.form.type,
      };
      getList(data).then((res) => {
        this.testData = res.data.list;
        this.total = res.data.total;
      });
    },
    //每次选择分类时获取数据
    handleTypeChange(val) {
      // debugger;
      this.TypeVar = "";

      // 获取患者列表
      var data = {
        uuid: this.uuid,
        page: this.currentPage,
        gender: this.PlatformVar,
        clinical_situation: this.form.type,
      };
      getList(data).then((res) => {
        this.testData = res.data.list;
        this.total = res.data.total;
      });
    },
    //每次选择分类全部时获取数据
    AllButton() {
      this.TypeVar = "all";
      this.form.type = [];
      // 获取患者列表
      var data = {
        uuid: this.uuid,
        page: this.currentPage,
        gender: this.PlatformVar,
        clinical_situation: this.TypeVar,
      };
      getList(data).then((res) => {
        this.testData = res.data.list;
        this.total = res.data.total;
      });
    },
    // onRadioChange(e) {
    //   console.log(e.target.tagName);
    //   if (e.target.tagName === "INPUT") {
    //     if (this.form.radio === "") {
    //       this.form.radio = "1";
    //     } else {
    //       this.form.radio = "";
    //     }
    //   }
    // },

    search() {
      // 获取患者列表
      var data = {
        uuid: this.uuid,
        search: this.input,
      };
      getList(data).then((res) => {
        this.testData = res.data.list;
        this.total = res.data.total;
      });
    },
    // 跳转页面
    Jump(route) {
      this.$router.push(route);
    },
    PatientData(PatientUuid) {
      localStorage.setItem("caseDetail", JSON.stringify(PatientUuid));
      this.$router.push("/caseDetail");
    },

    //选分页时的获取数据
    handlePageChange(currentPage) {
      this.currentPage = currentPage; // 当前页码改变时更新数据
      // 获取患者列表
      var data = {
        uuid: this.uuid,
        page: this.currentPage,
        gender: this.PlatformVar,
        clinical_situation: this.TypeVar,
      };
      getList(data).then((res) => {
        this.testData = res.data.list;
      });
    },
    //注销信息
    LogOff() {
      localStorage.removeItem("token"); //清除本地储存
      this.$router.push("/");
    },
    gotoThisPath(language) {
      let str = this.$route.path.split("/");
      let path = "";
      if (str.length >= 3) {
        for (let i = 2; i <= str.length - 1; i++) {
          path += "/" + str[i];
        }
      } else {
        path = this.$route.path;
      }
      if (path == "/zh-en" || path == "/zh-mo") {
        path = "";
      }

      if (language == "/") {
        language = "";
      }
      if (language == path && path == "") {
        path = "/";
      }
      // this.$router.push({path: "/" })
      this.$router.push({ path: language + path });
    },
  },
};
</script>
  <style scoped>
.content {
  width: 100%;
  /* height: 140px; */
  min-width: 1248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bannerImg {
  width: 100%;
}
.rectangle {
  /* position: absolute; */
  width: 100%;
  height: 60px;
  /* right: 0px;
  top: 80px; */
  vertical-align: middle;
}
.header_inner {
  padding: 0 calc(50% - 624px);
  background: rgba(50, 62, 72, 1);
  min-width: 1248px;
  height: 60px;
}
.logoImg {
  float: left;
  height: 60px;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 61px;
  color: rgba(64, 255, 153, 1);
  vertical-align: top;
}
.spanDiv {
  width: calc(100% - 145px);
  /* margin-left: calc(11.61% + 144px); */
  display: inline-block;
  height: 60px;
  font-size: 15px;
  vertical-align: middle;
  clear: both;
}
.spanChoosen {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
}
.spanClass {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}
.spanClass {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}
.inputClass {
  width: 260px;
  height: 36px;
  background: rgba(255, 255, 255, 0.93);
  border-radius: 18px;
  /* margin-left: calc(62.75% - 144px); */
  margin-top: 12px;
  padding: 0 8px 0 40px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAPFBMVEUAAACEhISFhYWEhISEhISEhISHh4eBgYGDg4N/f3+EhISEhISGhoaEhISFhYWEhISDg4ODg4OEhISEhIQp+ctsAAAAE3RSTlMAXzDvn98gH0AQv3A/z6+PgFBPOylbNQAAAPJJREFUSMftlNsOgjAMhte129hEEP/3f1czjQe0HZB4YQz/Hdu+0rPb9UfyRDR1a193wriKZQ3jC15U0tL7AwM8kE/Jk1Tk0H5/AjDm+1esyKlpHwj9zMHQ/EcEQno7CmBvAgX4uOyAYuYTkM/TEbCyK4CSxcwQAzBuBrDpEWnnBOhhT8aFr4YMS0lN9vcA26Wz3qd20L2V1kEvD5uF46yGIHZrjGr9+0bzdUpkxR5P1tobqT1AfuZlAEJeGFF5WMwjqo65PaSA3JbAwACHJSLO14yPi4Trn4us5lMhFOZMRLfodcLWg5jcNoLcJkVyu35MF3jaDuxFLqWoAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 11.5px 5.78px;
  border: 0px;
  background-size: 24px 24px;
}
.ivu-dropdown {
  width: 80px;
  /* height: 111px; */
  /* margin-top: 17px; */
  margin-left: -55px;
}
.ivu-dropdown-rel {
  position: relative;
}
.ivu-icon {
  display: inline-block;
  font-family: Ionicons;
  speak-as: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: optimizeLegibility;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: -0.125em;
  text-align: center;
}
.ivu-select-dropdown {
  width: inherit;
  overflow: visible;
  max-height: none;
  margin: 5px 0;
  padding: 5px 0;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 900;
}
.ivu-dropdown-menu {
  min-width: 100% !important;
  text-align: center;
}
.ivu-dropdown-item {
  margin: 0;
  line-height: normal;
  padding: 7px 7px;
  clear: both;
  color: #515a6e;
  font-size: 14px !important;
  white-space: nowrap;
  list-style: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}
.namespan {
  display: block;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  width: 110px;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-top: 20px;
  float: right;
}

.container-2 .ivu-dropdown-rel {
  position: relative !important;
  height: 30px !important;
  width: 30px !important;
  -webkit-transform: translate(20px, 2px);
  transform: translate(20px, 2px);
}
.container-2 {
  width: 1248px;
  margin: auto;
}
.CationBox {
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.row-2 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}
.row-2 > div {
  flex-shrink: 0;
  font-weight: 600;
  margin-right: 8px;
}
.row-2 .content-3 {
  flex: 1;
  display: flex;
}
.row-2 .content-3 .item {
  border-radius: 3px;
  color: #666;
  padding: 12px 20px;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  margin-right: 10px;
}
.row-2 .content-3 .actvCss {
  background: rgba(17, 184, 120, 1);
  color: #fff;
}

.nullCss {
  margin: 60px auto;
  font-size: 14px;
  text-align: center;
  color: #999;
}
.content-2 {
  width: 100%;
  text-align: center;
  /* margin-top: -30px; */
  padding-top: 20px;
  background-color: #eef2f6;
}
.content-titleFont {
  width: 1248px;
  margin: auto;
  display: flex;
  margin-top: 10px;
  /* margin-bottom: 30px; */
}
.titleFont {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #646464;
}
.blog-date-img-wrapper {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
}
.dentist-score {
  margin: 0;
}
.dentist-name {
  margin: 2px 0;
}
.dentist-score {
  margin: 0;
}
.dentist-data {
  margin-left: 10px;
}

.dentist-notes {
  width: 35%;
  line-height: 106px;
  /* //超出一行省略号 */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dentist-state {
  width: 15%;
  padding-top: 40px;
}

.dentist-img {
  width: 80px;
  height: 85px;
}
.dentist-office {
  width: 100%;
  max-width: 1248px;
  height: 106px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  margin-top: 15px;
  display: flex;
  list-style: none;
  background: rgb(255, 255, 255);
  border-radius: 8px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  padding-left: 0;
  list-style: none;
  flex-direction: column;
}
.pagination >>> .btn-prev {
  margin-left: 100px;
}
.pagination >>> .btn-next {
  margin-right: 100px;
}
.pagination
  >>> .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: rgba(17, 184, 120, 1);
}
.el-dropdown-menu {
  top: 125px !important;
  left: 1365px !important;
}
.el-dropdown-menu >>> .popper__arrow {
  display: none;
}
.el-form >>> .el-form-item__label {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #323e48;
}
.el-form >>> .el-checkbox-button__inner {
  background: transparent;
  border: none;
  box-shadow: none;
}
.el-form >>> .is-checked {
  background: rgba(17, 184, 120, 1) !important;
  box-shadow: none;
  margin: 0 6px 6px 6px;
}
.el-radio-button__orig-radio {
  background-color: #323e48;
}
.all {
  border-radius: 3px;
  color: #666;
  padding: 11px 21px;
  font-size: 12px;
  border: none;
  margin-left: 6px;
  float: left;
  cursor: pointer;
}
.allcolor {
  background: rgba(17, 184, 120, 1);
  color: #fff;
}
.el-form >>> .el-form-item__content {
  margin-left: 130px !important;
}
h2,
h3,
h4,
h5,
button,
a {
  font-family: "GenSenMaruGothicTW-Bold";
}
p,
span,
div {
  font-family: "GenSenMaruGothicTW-Regular";
}
</style>