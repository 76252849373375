<template>
  <div>
    <div>
      <div class="content">
        <div style="text-align: center">
          <img
            src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/cases-3.png"
            class="bannerImg"
          />
        </div>
        <div class="rectangle">
          <Navigationen></Navigationen>
        </div>
      </div>
    </div>
    <div class="main">
      <ul class="menu-list">
        <li
          style="margin-top: 24px"
          @click="EditInformation(1)"
          :class="[edit == 1 || edit == 2 ? 'menuItemSelected' : 'menu-item']"
        >
          Personal Center
        </li>
        <li
          @click="EditInformation(3)"
          :class="[edit == 3 ? 'menuItemSelected' : 'menu-item']"
        >
          AccountSettings
        </li>
        <li
          @click="EditInformation(4)"
          :class="[edit == 4 ? 'menuItemSelected' : 'menu-item']"
        >
          mailing address
        </li>
      </ul>
      <div class="main-content" style="height: 560px">
        <div v-if="this.edit == 1" style="width: 100%; height: 100%">
          <div class="head-icon-div">
            <img
              src="http://static.file.eset3d.com/avatar.png"
              class="head-image"
            />
            <div
              style="
                width: 68px;
                height: 28px;
                display: flex;
                margin-left: 46px;
                cursor: pointer;
              "
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGhSURBVHgBrZbRTcMwEIb/i6hUJB6yAdmAskGzQTcoTABMQNgAJqAbABMQJsAjhA36gFRoacxd7LQlSZPY9KTIl/jy/fad7YTgYeO7nyn0OmE3AigF1pdpcpw1xRIczcJnlccZkMdNIgG84aRA+pqdOYqZBK/jZBF5C9TgGMTp7fABWMVtIr0E9qSlsDQ5UW0inQL1kUu+9YihDNJhlwi5wQcM+QoFYEA2VQnNi/jkk4UH0hfCFp5c4FvQIuonQim5wjcxrSLfE26eJGWBD1zMrPk8RkNN+L0rG5aRD7x9JuBLX5je1Tn9B94sUloux8csMAGcM0+4mD0iVBUuXlmDqW0zV7gZ4PKRm0kVviNAZXFe/OBlzv/CdwT02PDxDgfrghcCHDTa3On1Bw4ILwQ4KNreDhUOCLcCKGeg+uTfBV4KnBlXd6bHFS52hM3mIFUHSvq4RkSn0LIM7WLoCS+ovMm0de95FWUMkpSNbG3C+iv94XYG8lcgI+Pvq26Mkbpk3P/G1zPDUzgYCyxvePfK0RoZEAtSrqCJa5Krfb8jfe0Xtdknc1cQ2CsAAAAASUVORK5CYII="
                style="height: 24px"
              />
              <span @click="EditInformation(2)" class="edit">edit</span>
            </div>
          </div>
          <div style="text-align: center; margin-top: 12px">
            <span class="name">{{ DoctorInformation.name }}</span>
          </div>
          <div style="text-align: center; margin-top: 6px">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC2SURBVHgBzVJRDcIwEH2dgVXCkIADJCBhrQEkECRMQcEBEnAADjYJdVCu4UZGe7A1++ElL03u+l56fQeshJKKzjlNx4HYEj3xRuyMMcOsAYvvxCZpDcQtmfhpsUKOnSAG1/ZpUTLQKECFlSg18EsMrviOx6wB/3IniMUYMwOKsaGjFgxq7n1AJcIjXsvzC2fiaXyNYnGMrsfyCOOYmzjuOIJGWf7v+9MRWsgbmCGE0FtrL/gLPAFb/C32sm93oAAAAABJRU5ErkJggg=="
              style="height: 16px"
            />
            <span class="account">尚创峰医疗</span>
          </div>
          <div class="education"></div>
        </div>
        <div v-if="this.edit == 2" style="width: 100%; height: 633px">
          <div style="width: 100%; height: 160px">
            <div class="head-icon-div-edit">
              <!-- <img src="http://static.file.eset3d.com/avatar.png" /> -->
              <el-upload
                class="upload-demo"
                ref="upload"
                :action="uploadUrl"
                :on-success="(response) => handleSuccess(response, 17)"
                :before-upload="beforeUpload"
                :show-file-list="false"
                :headers="myHeaders"
                :data="photo_1"
              >
                <img v-if="imageUrl_17" :src="imageUrl_17" class="head-image" />
                <i v-else class="">
                  <img
                    style="width: 100%; border-radius: 100px"
                    src="http://static.file.eset3d.com/avatar.png"
                    alt=""
                /></i>
              </el-upload>
            </div>

            <!-- <div class="head-icon-div-edit2"></div> -->
          </div>
          <div style="margin-top: 63px">
            <span
              style="
                width: 104px;
                height: 28px;
                margin-left: 120px;

                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
                color: rgb(50, 50, 50);
              "
              >name:</span
            >
            <span
              style="
                margin-left: 32px;

                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
                color: rgb(50, 50, 50);
              "
              >{{ DoctorInformation.name }}</span
            >
          </div>
          <div
            style="
              margin-left: 120px;
              margin-top: 20px;
              height: 1px;
              margin-right: 120px;
              background: rgb(196, 196, 196);
            "
          ></div>
          <div style="margin-top: 20px">
            <span
              style="
                vertical-align: top;
                width: 104px;
                height: 28px;
                margin-left: 120px;

                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
                color: rgb(50, 50, 50);
              "
              >Remark:</span
            >
            <textarea
              class="jq_watermark"
              style="
                padding: 5px;
                width: 712px;
                height: 112px;
                margin-left: 32px;

                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
                background: rgb(250, 250, 250);
              "
            ></textarea>
          </div>
          <div
            style="
              height: 1px;
              margin-left: 120px;
              margin-right: 120px;
              margin-top: 20px;
              background: rgb(196, 196, 196);
            "
          ></div>
          <div
            style="
              height: 64px;
              width: 424px;
              margin-top: 64px;
              margin-left: 332px;
            "
          >
            <button
              style="
                width: 100%;
                height: 100%;
                background: rgba(17, 184, 120, 1);
                border-radius: 4px;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 34px;
                color: rgb(255, 255, 255);
                border: none;
              "
            >
              save
            </button>
          </div>
        </div>
        <div v-if="this.edit == 3" style="width: 100%; height: 100%">
          <div
            style="
              width: 848px;
              margin-left: 120px;
              display: flex;
              margin-top: 48px;
            "
          >
            <div style="width: 24px; height: 24px; margin-top: 4px">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD6SURBVHgB7ZTRDYIwFEVvazT6xwi4CW7gCk6gG1g2wAnESWQENrAj8KcxQq1AYhD7WjD4YTxfkPd6L4/mXWBgmGtjIJQHXJYAz4AiTcRMupyzGgTi7AOjPaCCl6MxkIc2I2YX50f96BtapJ5mQZlwkJDiqGqP6cwYJ6i//gQnJvNEMPmuwjEwP20wzeBO1tlAX5o+pCTsyKq3o0EFO8AKS6iqxWASgRi/quUh+hrUo6/MHcWud1SU4cZua6hio189QiKmMon1F3YzahgE4VULqwifwLhItuOwZdAte2w8s+mbm1xubpftNdHQad5B+ZvI/HdgmlKb/afFHXI7WzXGb33nAAAAAElFTkSuQmCC"
              />
            </div>
            <span
              style="
                margin-left: 35px;
                height: 28px;
                display: block;

                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
              "
              >Login account:</span
            >
            <span
              style="
                margin-left: 32px;
                display: block;

                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
              "
              >{{ DoctorInformation.account }}</span
            >
          </div>
          <div
            style="
              width: 848px;
              height: 1px;
              margin-left: 120px;
              margin-right: 120px;
              margin-top: 20px;
              background: rgb(196, 196, 196);
            "
          ></div>
          <div
            style="
              width: 848px;
              margin-left: 120px;
              display: flex;
              margin-top: 20px;
            "
          >
            <div style="width: 24px; height: 24px; margin-top: 4px">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADzSURBVHgB7ZRNCsIwEIXfjB6gR/AGdqGuPYIirr2CexGVuhfPoViP4LoI0pv0ADZjIgi1f6YFF0q/TchMZl5IZgb4dSht6PneCoK5djioCBHWwXi5Sdo4c0iwrpPcIPpiaVu78DRhLneEsIHJJZIdci5WKCBAeJ0uL7Cgd/CAVr6P8WUagUagESjpZD2TVn1/O4MFAnFM61cS0AzN9LKBSnxlAu9JhM+Aeg4/xdwlUSObOEsBugSTxThp6Z+8m17cT5GWAuIOjt6IW4jMTsVwFNAhi8g8AZMkPdcdYfjx60u4/N2TZMpU/+se9Yh05W3wdzwAYNE6Pe/goQAAAAAASUVORK5CYII="
              />
            </div>
            <span
              style="
                margin-left: 35px;
                height: 28px;
                display: block;

                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
              "
              >phone number:</span
            >
            <span
              style="
                margin-left: 32px;
                width: 37px;
                height: 28px;
                display: block;

                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
              "
              >+86</span
            >
            <span
              style="
                margin-left: 12px;
                width: 141px;
                height: 28px;
                display: block;

                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
              "
              >{{ DoctorInformation.phone }}</span
            >
          </div>
          <div
            style="
              width: 848px;
              height: 1px;
              margin-left: 120px;
              margin-right: 120px;
              margin-top: 20px;
              background: rgb(196, 196, 196);
            "
          ></div>
          <div
            style="
              width: 848px;
              margin-left: 120px;
              display: flex;
              margin-top: 20px;
            "
          >
            <div style="width: 24px; height: 24px; margin-top: 4px">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFbSURBVHgB7ZQ/TsMwFMafHVTRLTegHVOx9ACpkhtwA4iEKJlITtD0BmVCBKSUkxA13ZlIR8IN2Foq2Q87rcWfFJpCEQz5DZb97Pe+70V2ACr+GqImk+vOIWc4EAEdfkZGNBIYx6MbuaAqigyCLRSXNFAYVQv6GseGHAkHR4wZbE5GONrLub5CYIHhJsPnGm1zxHMoiTxbr2ltwx3HH/cKAvehGe3OQN8/HXuEa80vuyEQS9fy7EzkpKF5u1aAIBwhZXfppekZbpy1uiMhwn0EeFJnFnPut04SW7pOw84ZFzliwyp6WCIKYtGi/K6aLYUmF1YDgQWgwR5hmpPHriwLkfVWFW51E1JGQPkY1Gu033TivIuHyNKnc94TvXifZSiBHSgFetM5OxBvJQCkj6J4pG7dOkoK5Mj7PQRgsAkUfplKoLzA24e0BbKCAOXgf/Mn9w5plHPoQ8W/4QUbso4NN0h+/wAAAABJRU5ErkJggg=="
              />
            </div>
            <span
              style="
                margin-left: 35px;
                display: block;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
              "
              >email address:</span
            >
            <span
              style="
                margin-left: 32px;
                height: 28px;
                display: block;

                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
              "
              >{{ DoctorInformation.email }}</span
            >
          </div>
          <div
            style="
              width: 848px;
              height: 1px;
              margin-left: 120px;
              margin-right: 120px;
              margin-top: 20px;
              background: rgb(196, 196, 196);
            "
          ></div>
          <div
            style="
              width: 424px;
              height: 64px;
              margin-left: 332px;
              margin-top: 64px;
            "
          >
            <button @click="LogOff()" class="logout">sign out</button>
          </div>
        </div>
        <div v-if="this.edit == 4" style="width: 100%; height: 100%">
          <ul
            style="
              list-style: none;
              width: 848px;
              height: 68px;
              margin-left: 120px;
              margin-top: 48px;
            "
          >
            <li
              style="
                display: flex;
                width: 100%;
                height: 100%;
                background: rgb(255, 255, 255);
                border: 1px dashed rgb(64, 128, 249);
                box-sizing: border-box;
                border-radius: 4px;
                cursor: pointer;
              "
              @click="dialogFormVisible = true"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA5SURBVHgB7ZOxCQAwDMPcXtbT+nanhnwgQpaABdmC8GBLgHPfzyO/W81YaGGBRQtLaU+I8PQsnCYMDd4NvPVttcoAAAAASUVORK5CYII="
                style="
                  width: 20px;
                  height: 20px;
                  margin-top: 24px;
                  margin-left: 24px;
                "
              />
              <span
                style="
                  margin-top: 20px;
                  margin-left: 24px;
                  display: block;
                  height: 28px;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 20px;
                  line-height: 28px;
                  color: rgb(64, 128, 249);
                "
                >Add mailing address</span
              >
            </li>
            <el-dialog
              title="Add mailing address"
              :visible.sync="dialogFormVisible"
            >
              <el-form :model="form" :rules="rules2" ref="form">
                <p style="line-height: 14px; margin: 0px 0px 20px 40px">
                  Create a new address to receive orthodontic appliances or
                  related documents
                </p>

                <el-form-item
                  style="margin-bottom: 22px"
                  prop="clinic"
                  label="Clinic name"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    placeholder="Clinic cannot be empty"
                    style="width: 400px"
                    v-model="form.clinic"
                    autocomplete="off"
                  ></el-input> </el-form-item
                ><el-form-item
                  style="margin-bottom: 22px"
                  prop="consignee"
                  label="The recipient's name"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    placeholder="Recipient name cannot be empty"
                    style="width: 400px"
                    v-model="form.consignee"
                    autocomplete="off"
                  ></el-input> </el-form-item
                ><el-form-item
                  style="margin-bottom: 22px"
                  prop="phone"
                  label="The recipient's phone"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    placeholder="Mobile phone number, landline number, extension number, etc."
                    style="width: 400px"
                    v-model="form.phone"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  style="margin-bottom: 22px"
                  prop="address"
                  label="receiver's address"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    placeholder="Please enter the recipient's address, down to the house number."
                    style="width: 400px"
                    v-model="form.address"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  style="margin-bottom: 22px"
                  prop="postcode"
                  label="post code"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    placeholder="Please enter your location zip code"
                    style="width: 400px"
                    v-model="form.postcode"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button
                  style="margin-right: 100px"
                  @click="dialogFormVisible = false"
                  >Cancel</el-button
                >
                <el-button type="primary" @click="dialogFormVisible2('form')"
                  >Sure</el-button
                >
              </div>
            </el-dialog>
          </ul>

          <ul
            style="
              margin-top: 20px;
              list-style: none;
              width: 848px;
              margin-left: 120px;
              height: 552px;
              overflow: overlay;
            "
          >
            <li
              class="addressli"
              style="margin-top: 0px"
              v-for="item in data"
              :key="item.id"
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA1AAAAAECAYAAACA7N5NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEpSURBVHgB7c6xTsJQGIbhv7UN9AIcWBhg8ALwjkxYjezcArIZu7l6JybcgAMm0ji7CS2cc7R183M8IWnyvtM/ff+T3D59jc3nVxapxrvdw83wtb0/1utxVtfRttOm2V0ul9027t9wa7g13BpuDbeGW8Ot4dZwa7i1vrrTYPnUIpWaHdzL4K29q9WqSOs62rZz7vBeVd323XMocOP+L9wabg23hlvDreHWcGu4Ndxan91p4i2zCIXUTvaZbcoyOW7m83zg/eznQZRtb3Zyw+HmuiyP88eQ+73NcOP+G24Nt4Zbw63h1nBruDXcGm6t7+4oY22JZdv7RbJv79F0OgnOFRapQQjb0WLRbRdZPTF/EW0bt4Zbw63h1nBruDXcGm4Nt4Zbw62dw/0N4oYMkDm0w6gAAAAASUVORK5CYII="
                style="width: 847px; height: 4px"
              />
              <div style="width: 100%; display: flex; margin: 10px 0">
                <div style="width: 48px; height: 48px; margin-left: 24px">
                  <span
                    style="
                      text-align: center;
                      width: 48px;
                      height: 48px;
                      margin-left: 13px;
                      display: table-cell;

                      font-style: normal;
                      font-weight: 500;
                      font-size: 22px;
                      line-height: 31px;
                      color: rgb(39, 114, 255);
                      vertical-align: middle;
                      background: rgb(211, 227, 255);
                      border-radius: 24px;
                    "
                    >{{ item.clinic.charAt(0) }}
                  </span>
                </div>
                <div style="width: 639px; height: 67px; margin-left: 18px">
                  <div style="display: flex">
                    <span
                      style="
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 144px;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 34px;
                        color: rgb(50, 50, 50);
                      "
                      >{{ item.clinic }}</span
                    >
                    <span
                      style="
                        display: block;
                        margin-left: 24px;

                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        line-height: 28px;
                        color: rgb(132, 132, 132);
                      "
                      >{{ item.consignee }} {{ item.phone }}</span
                    >
                  </div>
                  <div style="margin-top: 8px">
                    <span
                      style="
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                        width: 639px;
                        height: 25px;

                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 25px;
                        color: rgb(50, 50, 50);
                      "
                      >{{ item.address }}
                    </span>
                  </div>
                </div>
                <el-button
                  type="success"
                  style="height: 40px"
                  @click="DeleteAddress(item.uuid)"
                  >Delete address</el-button
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Navigationen from "@/components/Navigationen.vue";
import {
  GetShippingAddress,
  ShippingAddress,
  DeleteDelivery,
} from "@/api/outpatient";
export default {
  metaInfo: {
    link: [],
  },
  components: { Navigationen },
  data() {
    return {
      edit: 1,
      uuid: "",
      data: [],
      dialogFormVisible: false,
      form: {
        clinic: "",
        consignee: "",
        phone: "",
        address: "",
        postcode: "",
      },
      formLabelWidth: "120px",
      rules2: {
        clinic: [
          {
            required: true,
            message: "Clinic cannot be empty",
            trigger: "blur",
          },
        ],
        consignee: [
          { required: true, message: "Name cannot be empty", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "Phone cannot be empty", trigger: "blur" },
        ],
        address: [
          {
            required: true,
            message: "Address cannot be empty",
            trigger: "blur",
          },
        ],
      },
      DoctorInformation: "",
      uploadUrl: "https://cases.mybeame.info/api/file/upload",
      myHeaders: {},
      imageUrl_17: "",
    };
  },
  mounted() {
    // 获取配送地址
    this.uuid = JSON.parse(localStorage.getItem("uuid"));
    GetShippingAddress(this.uuid).then((res) => {
      this.data = res.data;
    });
    // 获取个人信息
    let DoctorInformation = JSON.parse(
      localStorage.getItem("DoctorInformation")
    );
    for (let i = 0; i < DoctorInformation.length; i++) {
      console.log(DoctorInformation[i].name, DoctorInformation[i].age);
    }
    this.DoctorInformation = DoctorInformation;

    // 上传的 标头参数
    var data = JSON.parse(sessionStorage.getItem("token"));
    this.myHeaders.Authorization = "Bearer " + data;
    this.photo_1 = { uuid: this.uuid, type: "17" };
  },
  methods: {
    // 跳转
    // Jump(route) {
    //   this.$router.push(route);
    // },
    LogOff() {
      localStorage.removeItem("token"); //清除本地储存
      this.$router.push("/");
    },
    // 赋值
    EditInformation(data) {
      this.edit = data;
    },

    // 新增配送地址
    dialogFormVisible2(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          var Data = {
            clinic: this.form.clinic,
            consignee: this.form.consignee,
            phone: this.form.phone,
            address: this.form.address,
            postcode: this.form.postcode,
            uuid: this.uuid,
          };
          ShippingAddress(Data).then((res) => {
            if (res.code == 200) {
              this.dialogFormVisible = false;
              this.data = res.data;
              this.$message.success("added successfully");
            } else {
              this.$message.error("Failed to add");
            }
          });
        } else {
          this.$message.error("Failed to add");
          return false;
        }
      });
    },
    // 删除地址
    DeleteAddress(uuid) {
      var uuid = {
        uuid: uuid,
      };
      DeleteDelivery(uuid).then((res) => {
        if (res.code == 200) {
          this.data = res.data;
          this.$message.success("successfully deleted");
        } else {
          this.$message.error("failed to delete");
        }
      });
    },
    // 上传限制
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error(
          "The size of the uploaded avatar image cannot exceed 2MB!"
        );
      }
      return isLt2M;
    },
    // 上传图片时 图片的ID  和图片
    handleSuccess(response, index) {
      this["imageUrl_" + index] = response.data.url;
      this["imageUuid_" + index] = response.data.uuid;
    },
  },
};
</script>
  <style scoped>
.content {
  width: 100%;
  height: 140px;
  min-width: 1248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bannerImg {
  width: 100%;
}
.rectangle {
  position: absolute;
  width: 100%;
  height: 60px;
  right: 0px;
  top: 80px;
  vertical-align: middle;
}
.header_inner {
  padding: 0 calc(50% - 624px);
  background: rgba(50, 62, 72, 1);
  min-width: 1248px;
  height: 60px;
}
.logoImg {
  /* height: 40px; */
  /* margin-left: 11.61%; */
  float: left;
  height: 60px;
}
.spanDiv {
  width: calc(100% - 145px);
  /* margin-left: calc(11.61% + 144px); */
  display: inline-block;
  height: 60px;
  font-size: 15px;
  vertical-align: middle;
  clear: both;
}
.spanChoosen {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
}
.spanClass {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}

.inputClass {
  width: 260px;
  height: 36px;
  background: rgba(255, 255, 255, 0.93);
  border-radius: 18px;
  /* margin-left: calc(62.75% - 144px); */
  margin-top: 12px;
  padding: 0 8px 0 40px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAPFBMVEUAAACEhISFhYWEhISEhISEhISHh4eBgYGDg4N/f3+EhISEhISGhoaEhISFhYWEhISDg4ODg4OEhISEhIQp+ctsAAAAE3RSTlMAXzDvn98gH0AQv3A/z6+PgFBPOylbNQAAAPJJREFUSMftlNsOgjAMhte129hEEP/3f1czjQe0HZB4YQz/Hdu+0rPb9UfyRDR1a193wriKZQ3jC15U0tL7AwM8kE/Jk1Tk0H5/AjDm+1esyKlpHwj9zMHQ/EcEQno7CmBvAgX4uOyAYuYTkM/TEbCyK4CSxcwQAzBuBrDpEWnnBOhhT8aFr4YMS0lN9vcA26Wz3qd20L2V1kEvD5uF46yGIHZrjGr9+0bzdUpkxR5P1tobqT1AfuZlAEJeGFF5WMwjqo65PaSA3JbAwACHJSLO14yPi4Trn4us5lMhFOZMRLfodcLWg5jcNoLcJkVyu35MF3jaDuxFLqWoAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 11.5px 5.78px;
  border: 0px;
  background-size: 24px 24px;
}

.namespan {
  display: block;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  width: 110px;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-top: 20px;
  float: right;
}
.content .ivu-dropdown-rel {
  position: relative !important;
  height: 30px !important;
  width: 30px !important;
  -webkit-transform: translate(20px, 2px);
  transform: translate(20px, 2px);
}
.main {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #ffffff;
}
.menu-list {
  height: 204px;
  width: 160px;
  list-style: none;
  cursor: pointer;
  margin-left: calc(50% - 1248px / 2);
}
.menu-item {
  height: 68px;
  display: block;
  padding-top: 19px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 160%;
  color: #848484;
}
.menuItemSelected {
  height: 68px;
  display: block;
  padding-top: 19px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 160%;
  color: rgba(17, 184, 120, 1) !important;
  border-right: 3px solid rgba(17, 184, 120, 1);
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(64, 128, 249, 0.07)),
    to(rgba(64, 128, 249, 0))
  );
  background: linear-gradient(
    270deg,
    rgba(64, 128, 249, 0.07) 0%,
    rgba(64, 128, 249, 0) 100%
  );
}
.head-icon-div {
  width: 112px;
  height: 112px;
  margin-left: 488px;
  margin-top: 48px;
  background: #ffffff;
  border-radius: 60px;
  display: -webkit-box;
}
.head-image {
  width: 112px;
  height: 112px;
  border-radius: 60px;
}
.main-content {
  width: 1088px;
  left: 256px;
  margin-top: 24px;
  background: #fafafa;
  margin-bottom: 40px;
}
.edit {
  width: 40px;
  height: 28px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #4080f9;
}
.name {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1e3663;
}
.position {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #586b8e;
}
.account {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #afafaf;
}
.education {
  height: 104px;

  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #7a7a7a;
  margin-top: 32px;
}
.head-icon-div-edit {
  width: 112px;
  height: 112px;
  border-radius: 60px;
  margin-left: 488px;
  margin-top: 48px;
  position: absolute;
  background: rgba(0, 0, 0, 0.51);
}
.head-icon-div-edit1 {
  width: 68px;
  height: 68px;
  margin-left: 510px;
  margin-top: 48px;
  position: absolute;
}
.head-icon-div-edit2 {
  width: 112px;
  height: 112px;
  position: absolute;
  margin-left: 488px;
  margin-top: 48px;
  position: absolute;
  background: rgba(0, 0, 0, 0.51);
  border-radius: 60px;
}
.logout {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: rgba(17, 184, 120, 1);
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
.el-dialog__wrapper >>> .el-dialog__title {
  font-size: 22px;
}
.el-dialog__wrapper >>> .el-dialog__header {
  padding: 50px 20px 0 190px;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0px 30px 30px 150px;
}
.el-dialog__wrapper >>> .el-dialog__footer {
  text-align: center;
}
h2,
h3,
h4,
h5,
button,
span,
a {
  font-family: "Omnium-Bold";
}
p,
div {
  font-family: "Comfortaa-Bold";
}
</style>