<template>
  <div>
    <div class="content">
      <div style="text-align: center">
        <img
          src="https://beame.oss-cn-hongkong.aliyuncs.com/mobilePicture/cases-3.png"
          class="bannerImg"
        />
      </div>
      <div class="rectangle">
        <div class="header_inner">
          <span class="logoImg">be@me</span>
          <div class="spanDiv">
            <div style="float: left; width: 320px">
              <span @click="Jump('/zh-en/Home')" class="spanClass">HOME </span>
              <span
                @click="Jump('/zh-en/caseList')"
                class="spanChoosen"
                style="margin-left: 41px"
                >CASE
              </span>
              <span
                @click="Jump('/zh-en/patientListNew')"
                class="spanClass"
                style="margin-left: 50px"
                >PATIENT
              </span>
            </div>
            <div
              style="float: right; width: calc(100% - 350px); text-align: right"
            >
              <input
                v-model="input"
                placeholder="Search patient name/record number"
                type="text"
                @input="search()"
                class="inputClass"
                style="margin-right: 50px"
              />
              <div style="height: 60px; width: 220px; float: right">
                <span title="Name" class="namespan">{{
                  DoctorInformation.name
                }}</span>

                <img
                  @click="gotoThisPath('/')"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAMAAADypuvZAAAAP1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////9Du/pqAAAAFHRSTlMAYH/fPzAQ758fII9vv4DPr0+gUKu28wAAAAGpSURBVEjHnZbdloMgDIQDAUP5Ubs77/+sW7urURcVOzc9gX5OguYQqsjnMDBe4pKypwa5JNiIg79CCgAJ+fnoXtHD5cAABnOBiHW7ZCeO4wHSpQnpKjuGgdTVmMiAIjvZupkX8EnFkSH/tg0Q1KaW/AiYnQ9gNTpM0W/MRZkzSuLKmjFW8nX7tS+wlpA0qEP68LQkB0RqgcjrIsPSBaRllXmbqRXq5G91gGmE1MqrkVkpAGkdb60CwgzhRGqV3sfg70AO8pudQukxKwPPJUgKkbxMMsIKsvWDsCsoIFNCvgdNLgXuHvSNgRiPe1AEk4aNUAfch+hzqLsNfXYQA54KHUshh0IB+R6UkXafUbGzRiAsQdl9Rh5yo6a5K0Q3mXMdyszTj/ZsgqXWdl961kG6Vkh7tsC2QwYDkVq1QQxDpFZNUA9e3jF8GxSB+NkFoEFogcbNw6Ogv4Z6SNzdIf0V1Gvp+p/x/KL+AkxtJIjHjGeIrw8f/ZFND3A8GnPYVBHRMadiVpnU3IQUR8cyAyYuuzg5xO8cBKeITmobSVLkjMupvEkeQnUc/QEdFijuTK/OWgAAAABJRU5ErkJggg=="
                  style="
                    width: 24px;
                    height: 24px;
                    transform: translateY(13px);
                    cursor: pointer;
                  "
                />
                <span style="margin-left: 22px">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <img
                        v-if="
                          DoctorInformation != null && DoctorInformation != ''
                        "
                        :src="DoctorInformation.avatar"
                        style="
                          width: 30px;
                          height: 30px;
                          transform: translateY(16px);
                        "
                      />
                      <img
                        v-else
                        src="http://static.file.eset3d.com/avatar.png"
                        style="
                          width: 30px;
                          height: 30px;
                          transform: translateY(16px);
                        "
                      />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        ><p
                          @click="Jump('/zh-en/personalcenter')"
                          style="margin: 0px"
                        >
                          Personal Center
                        </p></el-dropdown-item
                      >
                      <el-dropdown-item
                        ><p @click="LogOff()" style="margin: 0px">
                          Log out
                        </p></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="statDiv">
      <div style="height: 92px; margin-left: calc(50% - 614px)">
        <div
          style="
            width: 124px;
            height: 60px;
            float: left;
            margin-top: 16px;
            text-align: center;
          "
        >
          <span
            style="
              height: 60px;
              color: rgb(50, 50, 50);
              font-family: 'PingFang SC';
              font-style: normal;
              display: block;
              font-weight: 500;
              font-size: 40px;
              line-height: 67px;
            "
            ><span style="cursor: pointer"
              >{{ CaseNumber.totalNum }}
            </span></span
          >
        </div>
        <div style="float: left">
          <div style="margin-top: 21px">
            <span
              style="
                font-family: 'PingFang SC';
                font-style: normal;
                display: block;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: rgb(50, 50, 50);
              "
              >case management
            </span>
          </div>
          <div style="margin-top: 4px">
            <span
              style="
                color: rgb(132, 132, 132);
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                display: block;
              "
              >My ongoing cases</span
            >
          </div>
        </div>
      </div>
      <div style="float: left">
        <div
          :class="[TypeVar == '1' ? 'choosen' : '']"
          @click="programme('1')"
          style="float: left; width: 112px; height: 90px; cursor: pointer"
        >
          <div style="margin-top: 16px; width: 100%">
            <img src="@/img/caselogo-1.png" class="state-img" />
            <div class="number">
              <span style="cursor: pointer">
                {{ CaseNumber.pendingUploadNum }}
              </span>
            </div>
          </div>
          <div class="state-div">
            <span class="state"> To be uploaded </span>
          </div>
        </div>
        <div
          @click="programme('2')"
          :class="[TypeVar == '2' ? 'choosen' : '']"
          style="float: left; width: 112px; height: 90px; cursor: pointer"
        >
          <div style="margin-top: 16px; width: 100%">
            <img src="@/img/caselogo-2.png" class="state-img" />
            <div class="number">
              <span style="cursor: pointer">
                {{ CaseNumber.awaitingReceptionNum }}
              </span>
            </div>
          </div>
          <div class="state-div">
            <span class="state">To be received</span>
          </div>
        </div>
        <div
          @click="programme('3')"
          :class="[TypeVar == '3' ? 'choosen' : '']"
          style="float: left; width: 112px; height: 90px; cursor: pointer"
        >
          <div style="margin-top: 16px; width: 100%; padding-left: 5px">
            <img src="@/img/caselogo-3.png" class="state-img" />
            <div class="number">
              <span style="cursor: pointer">
                {{ CaseNumber.designProgressNum }}
              </span>
            </div>
          </div>
          <div class="state-div">
            <span class="state">In design</span>
          </div>
        </div>
        <div
          @click="programme('4')"
          :class="[TypeVar == '4' ? 'choosen' : '']"
          style="float: left; width: 112px; height: 90px; cursor: pointer"
        >
          <div style="margin-top: 16px; width: 100%">
            <img src="@/img/caselogo-4.png" class="state-img" />
            <div class="number">
              <span style="cursor: pointer">
                {{ CaseNumber.pendingReviewNum }}
              </span>
            </div>
          </div>
          <div class="state-div">
            <span class="state"> To be reviewed </span>
          </div>
        </div>
        <div
          @click="programme('5')"
          :class="[TypeVar == '5' ? 'choosen' : '']"
          style="float: left; width: 112px; height: 90px; cursor: pointer"
        >
          <div style="margin-top: 16px; width: 100%">
            <img src="@/img/caselogo-5.png" class="state-img" />
            <div class="number">
              <span style="cursor: pointer">
                {{ CaseNumber.underTreatmentNum }}
              </span>
            </div>
          </div>
          <div class="state-div">
            <span class="state"> In treatment </span>
          </div>
        </div>
        <div
          @click="programme('6')"
          :class="[TypeVar == '6' ? 'choosen' : '']"
          style="float: left; width: 112px; height: 90px; cursor: pointer"
        >
          <div style="margin-top: 16px; width: 100%">
            <img src="@/img/caselogo-5.png" class="state-img" />
            <div class="number">
              <span style="cursor: pointer"> {{ CaseNumber.doneNum }} </span>
            </div>
          </div>
          <div class="state-div">
            <span class="state">Completed</span>
          </div>
        </div>
      </div>
      <div style="margin-right: calc(50% - 614px)">
        <button
          @click="Jump('/zh-en/addCase/patientInfoNew')"
          v-if="
            DoctorInformation.permissions.indexOf(
              'add_complete_medical_case'
            ) !== -1
          "
          type="button"
          class="ivu-btn ivu-btn-default"
        >
          Add case
        </button>
      </div>
    </div>
    <div class="content-2">
      <div>
        <div class="content-2-titleFont">
          <ul style="width: 100%">
            <li style="display: flex; list-style: none; pointer-events: none">
              <div style="width: 20%; display: flex; justify-content: center">
                <span class="titleFont">patient</span>
              </div>
              <div style="width: 10%">
                <span class="titleFont">caseNo</span>
              </div>
              <div style="width: 30%">
                <span class="titleFont">remark</span>
              </div>
              <div style="width: 10%">
                <span title="" class="titleFont">visitTime</span>
              </div>
              <div style="width: 15%">
                <span title="" class="titleFont">submitTime</span>
              </div>
              <div style="width: 15%">
                <span title="" class="titleFont">status</span>
              </div>
              <div style="width: 15%">
                <span title="" class="titleFont">operation</span>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="testData.length">
          <div
            v-for="(item, index) in testData"
            :key="index"
            class="dentist-office"
          >
            <div @click="PatientData(item.uuid)" class="blog-date-img-wrapper">
              <img
                v-if="item.frontal_avatar != null && item.frontal_avatar != ''"
                class="dentist-img"
                :src="item.frontal_avatar"
                alt=""
              />
              <img v-else class="dentist-img" src="@/img/default.png" alt="" />

              <div class="dentist-data">
                <!-- <p class="dentist-score">{{ item.score }}</p> -->
                <p class="dentist-name">{{ item.name }}</p>
                <!-- <br /> -->
                <span v-if="(item.gender = 1)" class="dentist-gender"
                  >male</span
                >
                <span v-else class="dentist-gender">female</span>
                &nbsp;<span class="dentist-age dentist-gender"
                  >{{ item.age }}age</span
                >
                <p
                  v-if="item.turndown == 1"
                  class="dentist-name"
                  style="color: red"
                >
                  Dismissed
                </p>
              </div>
            </div>

            <div @click="PatientData(item.uuid)" class="dentist-id">
              <span>{{ item.medical_number }}</span>
            </div>
            <div
              @click="PatientData(item.uuid)"
              :title="item.notes"
              class="dentist-notes"
            >
              {{ item.remarks }}
            </div>
            <div @click="PatientData(item.uuid)" class="dentist-VisitTime">
              {{ item.created_at }}
            </div>
            <div @click="PatientData(item.uuid)" class="dentist-SubmissionYime">
              {{ item.submit_time }}
            </div>
            <div @click="PatientData(item.uuid)" class="dentist-state">
              {{ item.progress }}
            </div>
            <div class="dentist-description">
              <button
                @click="SubmitCase(item.uuid)"
                v-if="
                  TypeVar == '1' &&
                  DoctorInformation.permissions.indexOf(
                    'submit_medical_case'
                  ) !== -1
                "
                class="dentist-button"
              >
                Submit case
              </button>
              <button
                @click="ReceivingCases(item.uuid)"
                v-if="
                  TypeVar == '2' &&
                  DoctorInformation.permissions.indexOf(
                    'receive_medical_case'
                  ) !== -1
                "
                class="dentist-button"
              >
                take over
              </button>
              <button
                @click="UploadScheme(item.uuid)"
                v-if="
                  TypeVar == '3' &&
                  DoctorInformation.permissions.indexOf('upload_proposal') !==
                    -1
                "
                class="dentist-button"
              >
                Upload plan
              </button>
              <button
                @click="PatientData(item.uuid)"
                v-if="
                  TypeVar == '4' &&
                  DoctorInformation.permissions.indexOf('audit') !== -1
                "
                class="dentist-button"
              >
                to review
              </button>
              <button
                @click="schedule(item.uuid)"
                v-if="
                  TypeVar == '5' &&
                  DoctorInformation.permissions.indexOf('audit') !== -1
                "
                class="dentist-button"
              >
                Upload production progress
              </button>
            </div>
            <el-dialog
              title="Upload plan"
              :visible.sync="dialogVisible"
              width="35%"
            >
              <div>
                <div class="upload-border">
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="uploadUrl"
                    :on-success="(response) => handleSuccess(response, 1)"
                    :show-file-list="false"
                    :headers="myHeaders"
                    :data="photo_1"
                  >
                    <p class="upload-p" size="small" type="primary">
                      video1<br /><br />
                      + Click to upload
                    </p>
                  </el-upload>
                  <div v-if="imageUrl_1" style="width: 100%; font-size: 32px">
                    <i
                      class="el-icon-delete-solid"
                      @click="DeleteUpload(imageUuid_1, 1)"
                    ></i>
                    <img class="image" :src="imageUrl_1" />
                  </div>
                </div>
                <div class="upload-border">
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="uploadUrl"
                    :on-success="(response) => handleSuccess(response, 2)"
                    :show-file-list="false"
                    :headers="myHeaders"
                    :data="photo_2"
                  >
                    <p class="upload-p" size="small" type="primary">
                      video2<br /><br />
                      + Click to upload
                    </p>
                  </el-upload>
                  <div v-if="imageUrl_2" style="width: 100%; font-size: 32px">
                    <i
                      class="el-icon-delete-solid"
                      @click="DeleteUpload(imageUuid_2, 2)"
                    ></i>
                    <img class="image" :src="imageUrl_2" />
                  </div>
                </div>
                <div class="upload-border">
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="uploadUrl"
                    :on-success="(response) => handleSuccess(response, 3)"
                    :show-file-list="false"
                    :headers="myHeaders"
                    :data="photo_3"
                  >
                    <p class="upload-p" size="small" type="primary">
                      video3<br /><br />
                      + Click to upload
                    </p>
                  </el-upload>
                  <div v-if="imageUrl_3" style="width: 100%; font-size: 32px">
                    <i
                      class="el-icon-delete-solid"
                      @click="DeleteUpload(imageUuid_3, 3)"
                    ></i>
                    <img class="image" :src="imageUrl_3" />
                  </div>
                </div>
                <div class="upload-border">
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="uploadUrl"
                    :on-success="(response) => handleSuccess(response, 4)"
                    :show-file-list="false"
                    :headers="myHeaders"
                    :data="photo_4"
                  >
                    <p class="upload-p" size="small" type="primary">
                      video4<br /><br />
                      + Click to upload
                    </p>
                  </el-upload>
                  <div v-if="imageUrl_4" style="width: 100%; font-size: 32px">
                    <i
                      class="el-icon-delete-solid"
                      @click="DeleteUpload(imageUuid_4, 4)"
                    ></i>
                    <img class="image" :src="imageUrl_4" />
                  </div>
                </div>
                <div class="upload-border">
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="uploadUrl"
                    :on-success="(response) => handleSuccess(response, 5)"
                    :show-file-list="false"
                    :headers="myHeaders"
                    :data="photo_5"
                  >
                    <p class="upload-p" size="small" type="primary">
                      video5<br /><br />
                      + Click to upload
                    </p>
                  </el-upload>
                  <div v-if="imageUrl_5" style="width: 100%; font-size: 32px">
                    <i
                      class="el-icon-delete-solid"
                      @click="DeleteUpload(imageUuid_5, 5)"
                    ></i>
                    <img class="image" :src="imageUrl_5" />
                  </div>
                </div>
                <div class="upload-border">
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="uploadUrl"
                    :on-success="(response) => handleSuccess(response, 6)"
                    :show-file-list="false"
                    :headers="myHeaders"
                    :data="photo_6"
                  >
                    <p class="upload-p" size="small" type="primary">
                      Designing Documents<br /><br />
                      + Click to upload
                    </p>
                  </el-upload>
                  <div v-if="imageUrl_6" style="width: 100%; font-size: 32px">
                    <i
                      class="el-icon-delete-solid"
                      @click="DeleteUpload(imageUuid_6, 6)"
                    ></i>
                    <img class="image" :src="imageUrl_6" />
                  </div>
                </div>
                <el-form
                  style="margin: 20px"
                  ref="FormScheme"
                  :model="FormScheme"
                  label-width="110px"
                >
                  <el-form-item label="SchemeName">
                    <el-input
                      placeholder="Please enter which plan"
                      v-model="FormScheme.SchemeName"
                    ></el-input> </el-form-item
                  ><el-form-item label="Remark">
                    <el-input
                      type="textarea"
                      placeholder="Please enter content"
                      maxlength="150"
                      show-word-limit
                      v-model="FormScheme.remarks"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>

              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="SubmitProposal()"
                  >submit</el-button
                >
              </span>
            </el-dialog>
            <el-dialog
              title="audit plan"
              :visible.sync="dialogVisible_2"
              width="30%"
            >
              <div style="display: flex">
                <div style="width: 45%; text-align: left; padding-left: 20px">
                  <h3 style="margin-bottom: 0px">Conduct physician review</h3>
                  <p style="margin-top: 0px">
                    Completed review will be added to the doctor’s message on
                    the left
                  </p>

                  <el-radio v-model="radio" label="1">agree</el-radio>
                  <br /><br />
                  <el-radio v-model="radio" label="2">reject</el-radio>
                </div>
                <div style="width: 50%">
                  <br />
                  <el-input
                    type="textarea"
                    placeholder="Please enter review comments..."
                    v-model="textarea"
                    maxlength="150"
                    show-word-limit
                  >
                  </el-input>
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_2 = false">Cancel</el-button>
                <el-button type="primary" @click="SubmitReview()"
                  >Submit review</el-button
                >
              </span>
            </el-dialog>
            <el-dialog
              title="production progress"
              :visible.sync="dialogVisible_3"
              width="30%"
            >
              <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="Total number of upper teeth">
                  <el-input v-model="form.upper_total"></el-input></el-form-item
                ><el-form-item label="Total number of lower teeth">
                  <el-input v-model="form.lower_total"></el-input
                ></el-form-item>
                <el-form-item label="Number of upper teeth completed">
                  <el-input v-model="form.upper_done"></el-input
                ></el-form-item>
                <el-form-item label="Lower teeth completed number">
                  <el-input v-model="form.lower_done"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_3 = false">Cancel</el-button>
                <el-button type="primary" @click="SubmissionProgress()"
                  >Submission progress</el-button
                >
              </span>
            </el-dialog>
          </div>
        </div>
        <div v-else class="nullCss">No relevant cases yet~</div>
      </div>
      <div class="pagination">
        <el-pagination
          :total="total"
          background
          layout="total,  prev, pager, next, jumper"
          :page-size="pageSize"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
    <Bottomen></Bottomen>
  </div>
</template>
  <script>
import Bottomen from "@/components/Bottomen.vue";

import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
locale.use(lang);
import {
  getList,
  statistics,
  submit,
  receive,
  review,
  addPlan,
  production,
  getProduction,
  DeleteFile,
} from "@/api/outpatient";
export default {
  metaInfo: {
    link: [],
  },
  components: {
    Bottomen,
  },
  data() {
    return {
      TypeVar: "1", // 表示当前选中的类型
      testData: [],
      currentPage: 1, // 当前页码
      pageSize: 5, // 每页显示的数据量
      uuid: "",
      CaseNumber: "",
      total: 0,
      DoctorInformation: "",
      input: "",
      dialogVisible: false,
      dialogVisible_2: false,
      dialogVisible_3: false,
      uploadUrl: "https://cases.mybeame.info/api/file/video",
      photo_1: {},
      photo_2: {},
      photo_3: {},
      photo_4: {},
      photo_5: {},
      photo_6: {},
      imageUrl_1: "",
      imageUrl_2: "",
      imageUrl_3: "",
      imageUrl_4: "",
      imageUrl_5: "",
      imageUrl_6: "",
      imageUuid_1: "",
      imageUuid_2: "",
      imageUuid_3: "",
      imageUuid_4: "",
      imageUuid_5: "",
      imageUuid_6: "",
      myHeaders: {},
      imageUrl_1: "",
      radio: "1",
      textarea: "",
      Reviewuuid: "",

      Uploaduuid: "",

      Scheduleuuid: "",
      FormScheme: {
        SchemeName: "",
        remarks: "",
      },
      form: {
        upper_total: "",
        lower_total: "",
        upper_done: "",
        lower_done: "",
      },
    };
  },
  created() {
    localStorage.removeItem("caseDetail"); //清除本地储存
    localStorage.removeItem("PatientID"); //清除本地储存
    localStorage.removeItem("ProgrammeID"); //清除本地储存

    // 上传头;
    var data = JSON.parse(sessionStorage.getItem("token"));
    this.myHeaders.Authorization = "Bearer " + data;

    // 获取个人信息
    let DoctorInformation = JSON.parse(
      localStorage.getItem("DoctorInformation")
    );
    for (let i = 0; i < DoctorInformation.length; i++) {
      console.log(DoctorInformation[i].name, DoctorInformation[i].age);
    }
    this.DoctorInformation = DoctorInformation;

    // 获取患者列表
    if (
      localStorage.getItem("uuid") != null &&
      localStorage.getItem("uuid") != ""
    ) {
      this.uuid = JSON.parse(localStorage.getItem("uuid"));

      var data = {
        uuid: this.uuid,
        limit: this.pageSize,
      };
      getList(data).then((res) => {
        this.testData = res.data.list;
        this.total = res.data.total;
      });
      // 获取患者病例的数量
      statistics(this.uuid).then((res) => {
        this.CaseNumber = res.data;
      });
    }
    // 生产进度
    getProduction(this.caseDetailUuid).then((res) => {
      if (res.code == 200) {
        this.form = res.data;
        console.log(this.schedule);
      }
    });
  },
  mounted() {
    // 导航栏状态
    var url = this.$route.name;
    if (
      url == "/zh-en/Home" ||
      url == "/zh-en/caseList" ||
      url == "/zh-en/patientListNew"
    ) {
      localStorage.setItem("route", JSON.stringify(this.$route.name));
      this.route = JSON.parse(localStorage.getItem("route"));
    }
    this.photo_1 = { type: "401" };
    this.photo_2 = { type: "402" };
    this.photo_3 = { type: "403" };
    this.photo_4 = { type: "404" };
    this.photo_5 = { type: "405" };
    this.photo_6 = { type: "406" };
  },
  computed: {},
  methods: {
    // 提交病例
    SubmitCase(uuid) {
      var data = {
        uuid: uuid,
      };
      submit(data).then((res) => {
        if (res.code == 200) {
          location.reload();
          this.$message.success("Submitted successfully");
        } else {
          this.$message.error("Submission Failed");
        }
      });
    },
    // 接收病例
    ReceivingCases(uuid) {
      var data = {
        uuid: uuid,
      };
      receive(data).then((res) => {
        if (res.code == 200) {
          location.reload();
          this.$message.success("Received successfully");
        } else {
          this.$message.error("Reception failed");
        }
      });
    },
    // 审核
    EnterReview(uuid) {
      this.dialogVisible_2 = true;
      this.Reviewuuid = uuid;
    },

    // 上传方案
    UploadScheme(uuid) {
      this.Uploaduuid = uuid;
      this.dialogVisible = true;
    },

    // 提交video方案
    SubmitProposal() {
      var data = {
        uuid: this.Uploaduuid,
        upper: this.imageUuid_1,
        lower: this.imageUuid_2,
        left: this.imageUuid_3,
        right: this.imageUuid_4,
        before: this.imageUuid_5,
        pdf: this.imageUuid_6,
        remarks: this.FormScheme.remarks,
        name: this.FormScheme.SchemeName,
      };
      addPlan(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("Submitted successfully");
          this.dialogVisible = false;

          location.reload();
        } else {
          this.$message.error("Submission Failed");
        }
      });
    },
    // 上传生产进度
    schedule(uuid) {
      this.Scheduleuuid = uuid;
      this.dialogVisible_3 = true;
    },
    SubmissionProgress() {
      var data = {
        uuid: this.Scheduleuuid,
        upper_total: this.form.upper_total,
        lower_total: this.form.lower_total,
        upper_done: this.form.upper_done,
        lower_done: this.form.lower_done,
      };
      production(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("Submitted successfully");
          this.dialogVisible = false;

          location.reload();
        } else {
          this.$message.error("Submission Failed");
        }
      });
    },

    // 跳转
    Jump(route) {
      this.$router.push(route);
    },

    // 搜索
    search() {
      // 获取患者列表
      var data = {
        uuid: this.uuid,
        search: this.input,
      };
      getList(data).then((res) => {
        this.testData = res.data.list;
        this.total = res.data.total;
      });
    },
    // 上传文件时 文件的ID  和图片
    handleSuccess(response, index) {
      this["imageUrl_" + index] = response.data.url;
      this["imageUuid_" + index] = response.data.uuid;
    },
    // 删除文件
    DeleteUpload(uuid, imageUrl) {
      DeleteFile(uuid)
        .then((res) => {
          if (res.code === 200) {
            switch (imageUrl) {
              case 1:
                this.imageUrl_1 = "";
                break;
              case 2:
                this.imageUrl_2 = "";
                break;
              case 3:
                this.imageUrl_3 = "";
                break;
              case 4:
                this.imageUrl_4 = "";
                break;
              case 5:
                this.imageUrl_5 = "";
                break;
              case 6:
                this.imageUrl_6 = "";
                break;
              case 7:
                this.imageUrl_7 = "";
                break;
              case 8:
                this.imageUrl_8 = "";
                break;
              case 9:
                this.imageUrl_9 = "";
                break;
              case 10:
                this.imageUrl_10 = "";
                break;
              case 11:
                this.imageUrl_11 = "";
                break;
              case 12:
                this.imageUrl_12 = "";
                break;
              case 13:
                this.imageUrl_13 = "";
                break;
              default:
                break;
            }
            this.$message.success("successfully deleted");
          } else {
            this.$message.error("failed to delete");
          }
        })
        .catch(() => {
          this.$message.error("failed to delete");
        });
    },
    // 分頁
    handlePageChange(currentPage) {
      this.currentPage = currentPage; // 当前页码改变时更新数据
      // 获取患者列表
      var data = {
        uuid: this.uuid,
        page: this.currentPage,
        limit: this.pageSize,
        state: this.TypeVar,
      };
      getList(data).then((res) => {
        this.testData = res.data.list;
      });
    },
    // 获取患者列表
    programme(TypeVar) {
      this.TypeVar = TypeVar;
      var data = {
        uuid: this.uuid,
        state: this.TypeVar,
        limit: this.pageSize,
      };
      getList(data).then((res) => {
        this.testData = res.data.list;
        this.total = res.data.total;
      });
    },
    // 跳转
    PatientData(PatientUuid) {
      localStorage.setItem("caseDetail", JSON.stringify(PatientUuid));
      this.$router.push("/zh-en/caseDetail");
    },

    LogOff() {
      localStorage.removeItem("token"); //清除本地储存
      this.$router.push("/zh-en");
    },
    gotoThisPath(language) {
      let str = this.$route.path.split("/");
      let path = "";
      if (str.length >= 3) {
        for (let i = 2; i <= str.length - 1; i++) {
          path += "/" + str[i];
        }
      } else {
        path = this.$route.path;
      }
      if (path == "/zh-en") {
        path = "";
      }

      if (language == "/") {
        language = "";
      }
      if (language == path && path == "") {
        path = "/";
      }
      // this.$router.push({path: "/" })
      this.$router.push({ path: language + path });
    },
  },
};
</script>
  <style scoped>
.content {
  width: 100%;
  height: 140px;
  min-width: 1248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.bannerImg {
  width: 100%;
}
.rectangle {
  position: absolute;
  width: 100%;
  height: 60px;
  right: 0px;
  top: 80px;
  vertical-align: middle;
}
.header_inner {
  padding: 0 calc(50% - 624px);
  background: rgba(50, 62, 72, 1);
  min-width: 1248px;
  height: 60px;
}
.logoImg {
  float: left;
  height: 60px;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 61px;
  color: rgba(64, 255, 153, 1);
  vertical-align: top;
}
.spanDiv {
  width: calc(100% - 145px);
  /* margin-left: calc(11.61% + 144px); */
  display: inline-block;
  height: 60px;
  font-size: 15px;
  vertical-align: middle;
  clear: both;
}
.spanChoosen {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
}
.spanClass {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}
.spanClass {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}
.inputClass {
  width: 260px;
  height: 36px;
  background: rgba(255, 255, 255, 0.93);
  border-radius: 18px;
  /* margin-left: calc(62.75% - 144px); */
  margin-top: 12px;
  padding: 0 8px 0 40px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAPFBMVEUAAACEhISFhYWEhISEhISEhISHh4eBgYGDg4N/f3+EhISEhISGhoaEhISFhYWEhISDg4ODg4OEhISEhIQp+ctsAAAAE3RSTlMAXzDvn98gH0AQv3A/z6+PgFBPOylbNQAAAPJJREFUSMftlNsOgjAMhte129hEEP/3f1czjQe0HZB4YQz/Hdu+0rPb9UfyRDR1a193wriKZQ3jC15U0tL7AwM8kE/Jk1Tk0H5/AjDm+1esyKlpHwj9zMHQ/EcEQno7CmBvAgX4uOyAYuYTkM/TEbCyK4CSxcwQAzBuBrDpEWnnBOhhT8aFr4YMS0lN9vcA26Wz3qd20L2V1kEvD5uF46yGIHZrjGr9+0bzdUpkxR5P1tobqT1AfuZlAEJeGFF5WMwjqo65PaSA3JbAwACHJSLO14yPi4Trn4us5lMhFOZMRLfodcLWg5jcNoLcJkVyu35MF3jaDuxFLqWoAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: 11.5px 5.78px;
  border: 0px;
  background-size: 24px 24px;
}

.namespan {
  display: block;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  width: 110px;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin-top: 20px;
  float: right;
}
.ivu-btn-default {
  margin-top: 26px;
  margin-left: 16px;
  width: 116px;
  height: 40px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: rgb(255, 255, 255);
  float: left;
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.statDiv {
  height: 92px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.number {
  color: rgb(50, 50, 50);
  margin-left: 5px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  background: rgb(80, 237, 171, 0.3);
  border-radius: 3px;
  width: 46px;
  height: 30px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.state-img {
  /* margin-left: 16px; */
  width: 40px;
  height: 40px;
  margin: 0;
  float: left;
  margin-top: -5px;
}
.state-div {
  width: 42px;
  height: 20px;
  margin-top: 8px;
  margin-left: 16px;
}
.state {
  height: 20px;
  width: 72px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgb(50, 50, 50);
  margin-left: 0px;
  display: block;
  text-align: center;
}
.choosen {
  background: rgba(80, 237, 177, 0.2);
  border-bottom: 2px solid rgba(17, 184, 120, 1);
}
.dentist-img {
  width: 80px;
  height: 85px;
}
.dentist-office {
  width: 100%;
  max-width: 1248px;
  height: 106px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  margin-top: 15px;
  display: flex;
  list-style: none;
  background: rgb(255, 255, 255);
  border-radius: 8px;
}
.content-2 {
  width: 100%;
  text-align: center;
  /* margin-top: -30px; */
  padding-top: 20px;
  background-color: #eef2f6;
  padding-bottom: 50px;
}
.content-2-titleFont {
  width: 1248px;
  margin: auto;
  display: flex;
  margin-top: 10px;
  /* margin-bottom: 30px; */
}
.titleFont {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #646464;
}
.blog-date-img-wrapper {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dentist-name {
  margin: 2px 0;
  width: 60px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dentist-gender {
  font-size: 12px;
}

.dentist-data {
  margin-left: 10px;
}
.dentist-id {
  width: 11%;
  padding-top: 40px;
}
.dentist-notes {
  width: 30%;
  line-height: 106px;
  /* //超出一行省略号 */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dentist-VisitTime {
  width: 10%;
  padding-top: 40px;
}
.dentist-SubmissionYime {
  width: 15%;
  padding-top: 40px;
}
.dentist-state {
  width: 15%;
  padding-top: 40px;
}
.dentist-description {
  width: 15%;
  padding-top: 40px;
}
.dentist-button {
  padding: 6px 15px 8px 15px;
  border: none;
  font-size: 16px;
  color: rgb(255, 255, 255);
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  cursor: pointer;
}

.pagination {
  margin-top: 10px;
  display: flex;
  padding-left: 0;
  list-style: none;
  flex-direction: column;
}
.pagination >>> .btn-prev {
  margin-left: 100px;
}
.pagination >>> .btn-next {
  margin-right: 100px;
}
.nullCss {
  margin: 60px auto;
  font-size: 14px;
  text-align: center;
  color: #999;
}
.pagination
  >>> .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: rgba(17, 184, 120, 1);
}
.el-dropdown-menu {
  top: 125px !important;
  left: 1365px !important;
}
.el-dropdown-menu >>> .popper__arrow {
  display: none;
}
.upload-demo >>> .el-upload-dragger {
  width: 200px;
}
.upload-border {
  width: 160px;
  height: 140px;
  margin: 20px;
  border: 2px solid #e8eaec;
  text-align: center;
  text-align-last: center !important;
  border-radius: 4px;
  position: relative;
  /* background-image: url(@/img/Avatar.png); */
  background-size: 100% 100%;
  display: inline-block;
}
.image {
  width: 160px;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
}
.upload-p {
  margin: 30px 0;
  line-height: 14px;
  font-size: 16px;
  color: #000;
}
.upload-border >>> .el-upload {
  width: 160px;
  height: 140px;
}
.el-textarea >>> .el-textarea__inner {
  height: 140px;
}
.el-icon-delete-solid {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  background: rgba(353, 255, 255, 0.7);
  color: #8ca37ec7;
  cursor: pointer;
}
h2,
h3,
h4,
h5,
button,
span,
a {
  font-family: "Omnium-Bold";
}
p,
div {
  font-family: "Comfortaa-Bold";
}
</style>