<template>
  <div>
    <div>
      <div class="container1">
        <div class="rectangle1">
          <Navigationen></Navigationen>
        </div>
      </div>
    </div>
    <div class="addCase-header">
      <div class="addCase-header-1">
        <div class="addCase-header-item">
          <span
            class="addCase-header-item-divide-left"
            style="display: none"
          ></span>
          <img src="@/img/patient-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Patient information</div>
        </div>
        <div class="addCase-header-item headerActive">
          <span class="addCase-header-item-divide-left"></span>
          <img style="width: 40px" src="@/img/CaseProcess-2.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Diagnostic information</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/information-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Treatment plan</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/information-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div></div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/download-1.png" />
          <span
            class="addCase-header-item-divide-right"
            style="display: none"
          ></span>
          <div>Data upload</div>
        </div>
      </div>
    </div>
    <div class="addCase-content">
      <div class="patientInfoNew">
        <div class="patientInfoNew-content">
          <p
            style="
              font-size: 20px;
              line-height: 160%;
              display: flex;
              align-items: center;
              color: rgb(0, 0, 0);
            "
          >
            2.Diagnostic information
          </p>
          <p
            style="
              height: 42px;
              font-size: 13px;
              line-height: 160%;
              color: rgb(85, 85, 85);
              margin-top: 8px;
            "
          >
            You can add up to two lines of filling help or notes here. If there
            is no setting, it will be blank. The text space here is designed to
            effectively solve the matters needing attention in data filling and
            reduce errors.
          </p>
          <img
            src="@/img/beame.png"
            style="
              width: 400px;
              height: 64px;
              position: absolute;
              top: 20px;
              right: 20px;
            "
          />
          <div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="Clinical situation">
                <el-checkbox-group v-model="form.type">
                  <el-checkbox-button label="1" value="type"
                    >Crowded</el-checkbox-button
                  >
                  <el-checkbox-button label="2" name="type"
                    >Spaced</el-checkbox-button
                  >
                  <el-checkbox-button label="3" name="type"
                    >Overbite</el-checkbox-button
                  >
                  <el-checkbox-button label="4" name="type"
                    >Overjet</el-checkbox-button
                  >
                  <el-checkbox-button label="5" name="type"
                    >ClassII1 malocclusion</el-checkbox-button
                  >
                  <el-checkbox-button label="6" name="type"
                    >ClassII2 malocclusion</el-checkbox-button
                  >
                  <el-checkbox-button label="7" name="type"
                    >Class Ⅲ malocclusion</el-checkbox-button
                  >
                  <el-checkbox-button label="8" name="type"
                    >Edge to edge bite</el-checkbox-button
                  >
                  <el-checkbox-button label="9" name="type"
                    >Open bite</el-checkbox-button
                  >
                  <el-checkbox-button label="10" name="type"
                    >Anterior Crossbite</el-checkbox-button
                  >
                  <el-checkbox-button label="11" name="type"
                    >Posterior Crossbite</el-checkbox-button
                  >
                  <el-checkbox-button label="12" name="type"
                    >Narrow Arch</el-checkbox-button
                  >
                  <el-checkbox-button label="13" name="type"
                    >Periodontal abscess</el-checkbox-button
                  >
                  <el-checkbox-button label="14" name="type"
                    >Oral inflammation</el-checkbox-button
                  >
                  <el-checkbox-button label="15" name="type"
                    >Bone rarefaction</el-checkbox-button
                  >
                  <el-checkbox-button label="16" name="type"
                    >TMJ problems</el-checkbox-button
                  >
                  <el-checkbox-button label="17" name="type"
                    >Protrusion</el-checkbox-button
                  >
                  <el-checkbox-button label="18" name="type"
                    >Smile line problem</el-checkbox-button
                  >
                  <el-checkbox-button label="19" name="type"
                    >Tooth deformity</el-checkbox-button
                  >
                </el-checkbox-group>
              </el-form-item>
              <div
                style="
                  border: 1px dashed rgb(196, 196, 196);
                  margin: 35px 0 35px 0;
                "
              ></div>
              <el-form-item label="treatment plan" label-width="100px">
                <el-input
                  type="textarea"
                  placeholder="Enter details"
                  v-model="form.desc"
                ></el-input>
                <p>
                  *Note: If the doctor's text scheme conflicts with the option,
                  the technician defaults to the text scheme design.
                </p>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div class="patientInfoNew_bottom1">
          <div class="patientInfoNew_bottom_content">
            <button
              @click="Jump('/zh-en/Home')"
              type="button"
              class="button-return"
            >
              <span>return</span>
            </button>
            <button @click="diagnostic()" type="button" class="button-Staging">
              <span>Staging</span>
            </button>
            <button
              @click="Jump('/zh-en/addCase/patientInfoNew')"
              type="button"
              class="button-step-upper"
            >
              <span>previous step</span>
            </button>
            <button
              @click="diagnostic()"
              type="button"
              class="button-step-colour"
            >
              <span>next step</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Navigationen from "@/components/Navigationen.vue";
import { diagnostic, getDiagnostic } from "@/api/outpatient";

export default {
  metaInfo: {
    link: [],
  },
  components: { Navigationen },
  data() {
    return {
      form: {
        type: [],
        desc: "",
      },
    };
  },

  mounted() {
    // 获取患者所有信息
    if (
      localStorage.getItem("PatientID") != null &&
      localStorage.getItem("PatientID") != ""
    ) {
      this.PatientID = JSON.parse(localStorage.getItem("PatientID"));
      getDiagnostic(this.PatientID).then((res) => {
        const { data } = res;
        const { clinical_situation, treatment_plan_desc } = data;
        if (res.code == 200) {
          this.form.type = clinical_situation ?? this.form.type;
          this.form.desc = treatment_plan_desc ?? this.form.desc;
        }
      });
    }
  },
  methods: {
    Jump(route) {
      this.$router.push(route);
    },
    diagnostic() {
      // this.$router.push("/addCase/transparentToothNew");

      var data = {
        uuid: JSON.parse(localStorage.getItem("PatientID")),
        clinical_situation: this.form.type,
        treatment_plan_desc: this.form.desc,
      };
      diagnostic(data).then((res) => {
        this.DefaultAddress = res.data;
        this.$router.push("/zh-en/addCase/transparentToothNew");
      });
    },
  },
};
</script>
  <style scoped>
.container1 {
  width: 100%;
  height: 60px !important;
  background: #00b0fa;
  top: 0px;
  min-width: 1248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rectangle1 {
  position: absolute;
  width: 100%;
  height: 60px;
  right: 0px;
  top: 0px;
  vertical-align: middle;
}
.bannerImg1 {
  min-width: 1248px;
  height: 60px;
  background: rgba(0, 0, 0, 0.64);
}
.header_inner1 {
  padding: 0 calc(50% - 624px);
  background: rgba(0, 0, 0, 0.64);
  min-width: 1248px;
  height: 60px;
}
.logoImg {
  /* height: 40px; */
  /* margin-left: 11.61%; */
  float: left;
  height: 60px;
}
.spanDiv {
  width: calc(100% - 145px);
  /* margin-left: calc(11.61% + 144px); */
  display: inline-block;
  height: 60px;
  font-size: 15px;
  vertical-align: middle;
  clear: both;
}
.spanClass {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}
.spanChoosen {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
}
.addCase-header {
  /* position: absolute; */
  width: 100%;
  height: 98px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  text-align: center;
}
.addCase-header-1 {
  position: relative;
  width: 1023px;
  left: calc(50% - 960px / 2);
  height: 98px;
  top: 0px;
}
.addCase-header-item {
  position: relative;
  width: 204px;
  height: 90px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  padding-top: 20px;
  float: left;
  cursor: pointer;
}
.headerActive {
  border-bottom: 3px solid rgba(17, 184, 120, 1);
}
.addCase-header-item-divide-left {
  width: 80px;
  border: 3px solid #f0f0f0;
  position: absolute;
  top: 36px;
  left: 0px;
}
.addCase-header-item-divide-right {
  width: 80px;
  border: 3px solid #f0f0f0;
  position: absolute;
  top: 36px;
}
.addCase-content {
  width: 100%;
  /* min-width: 1248px; */
  height: 900px;
  display: -webkit-box;
  display: -ms-flexbox;
  background-color: #eef2f6;
  display: flex;
  position: absolute;
}
.patientInfoNew {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.patientInfoNew-content {
  position: absolute;
  width: 960px;
  height: 700px;
  left: calc(50% - 960px / 2);
  top: 24px;
  background: #ffffff;
  padding: 20px 32px 50px 32px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
}

.patientInfoNew_bottom1 {
  position: fixed !important;
  width: 100%;
  height: 64px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ffffff;
}
.patientInfoNew_bottom_content {
  width: 960px;
  height: 64px;
  margin-left: calc(50% - 960px / 2);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #323232;
  position: relative;
}
.patientInfoNew_bottom_content button {
  cursor: pointer;
}
.upload-demo {
  margin: 0 20px;
  float: left;
}
.upload-demo >>> .el-upload-dragger {
  width: 200px;
}
.button-Staging {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 77px);
  top: 7px;
  border: 1px solid rgba(17, 184, 120, 1);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgb(255, 255, 255);
}
.button-step-upper {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 259px);
  top: 7px;
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-step-colour {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 413px);
  top: 7px;
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-return {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc((50% - 67px) - 413px);
  top: 7px;
  border: 1px solid rgba(17, 184, 120, 1);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgb(255, 255, 255);
}
.el-checkbox-group >>> .el-checkbox-button__inner {
  width: 120px;
  margin: 5px 10px;
  border: 1px solid #dcdfe6;
}
.el-textarea >>> .el-textarea__inner {
  width: 600px;
  height: 150px;
}
.el-checkbox-group
  >>> .el-checkbox-button.is-checked
  .el-checkbox-button__inner {
  background-color: rgba(17, 184, 120, 1);
  border-color: rgba(17, 184, 120, 1);
}
.el-checkbox-group >>> .el-checkbox-button__inner {
  width: 200px;
}
h2,
h3,
h4,
h5,
button,
span,
a {
  font-family: "Omnium-Bold";
}
p,
div {
  font-family: "Comfortaa-Bold";
}
</style>