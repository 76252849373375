<template>
  <div>
    <div>
      <div class="container1">
        <div class="rectangle1">
          <Navigationen></Navigationen>
        </div>
      </div>
    </div>
    <div class="addCase-header">
      <div class="addCase-header-1">
        <div class="addCase-header-item">
          <span
            class="addCase-header-item-divide-left"
            style="display: none"
          ></span>
          <img data-v-893d091a="" src="../../img/patient-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>患者資訊</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img data-v-893d091a="" src="../../img/diagnosis-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>診斷資訊</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="../../img/information-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>治療方案</div>
        </div>
        <div class="addCase-header-item headerActive">
          <span class="addCase-header-item-divide-left"></span>
          <img style="width: 40px" src="../../img/CaseProcess-3.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div></div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="../../img/download-1.png" />
          <span
            class="addCase-header-item-divide-right"
            style="display: none"
          ></span>
          <div>資料上傳</div>
        </div>
      </div>
    </div>
    <div class="addCase-content">
      <div class="patientInfoNew">
        <div class="patientInfoNew-content">
          <p
            style="
              font-size: 20px;
              line-height: 160%;
              display: flex;
              align-items: center;
              color: rgb(0, 0, 0);
            "
          >
            治療方案
          </p>
          <p
            style="
              width: 400px;
              height: 42px;
              font-size: 13px;
              line-height: 160%;
              color: rgb(85, 85, 85);
              margin-top: 8px;
            "
          >
            此處可添加最多兩行的填寫幫助或注意事項，若無設定則為空，此處設計文字空間望有效解決資料填寫中需注意的事項减少錯誤。
          </p>
          <img
            src="../../img/beame.png"
            style="
              width: 400px;
              height: 64px;
              position: absolute;
              top: 20px;
              right: 20px;
            "
          />
          <div>
            <p>10.不可移動牙齒</p>
            <el-checkbox-group v-model="checkboxGroup10" size="small">
              <el-checkbox-button
                v-for="city in cities"
                :label="city"
                :key="city"
                >{{ city }}</el-checkbox-button
              >
            </el-checkbox-group>
          </div>
          <div>
            <p>11.粘貼附件的牙位</p>
            <el-radio-group @change="extractatooth" v-model="radio2">
              <el-radio-button label="1">默認附件粘接位置</el-radio-button>
              <el-radio-button label="2">不可粘接附件位置</el-radio-button>
            </el-radio-group>
            <el-checkbox-group
              v-if="this.two == true"
              v-model="checkboxGroup11"
              size="small"
            >
              <el-checkbox-button
                v-for="city in cities"
                :label="city"
                :key="city"
                >{{ city }}</el-checkbox-button
              >
            </el-checkbox-group>
          </div>
          <div>
            <p>12.需預留間隙的牙比特</p>
            <el-checkbox-group v-model="checkboxGroup12" size="small">
              <el-checkbox-button
                v-for="city in cities"
                :label="city"
                :key="city"
                >{{ city }}</el-checkbox-button
              >
            </el-checkbox-group>
          </div>
          <div>
            <p>13.不可鄰面去釉的牙比特</p>
            <el-checkbox-group v-model="checkboxGroup13" size="small">
              <el-checkbox-button
                v-for="city in cities"
                :label="city"
                :key="city"
                >{{ city }}</el-checkbox-button
              >
            </el-checkbox-group>
          </div>
        </div>

        <div class="patientInfoNew_bottom1">
          <div class="patientInfoNew_bottom_content">
            <button @click="Jump('/Home')" type="button" class="button-return">
              <span>返回</span>
            </button>
            <button
              @click="TreatmentPlan()"
              type="button"
              class="button-Staging"
            >
              <span>暂存</span>
            </button>
            <button
              @click="Jump('/addCase/transparentToothNew')"
              type="button"
              class="button-step-upper"
            >
              <span>上一页</span>
            </button>
            <button
              @click="TreatmentPlan()"
              type="button"
              class="button-step-colour"
            >
              <span>下一页</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
const cityOptions = [
  "18",
  "17",
  "16",
  "15",
  "14",
  "13",
  "12",
  "11",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "48",
  "47",
  "46",
  "45",
  "44",
  "43",
  "42",
  "41",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
];
import Navigationen from "@/components/Navigationen.vue";

import { handle, getPreinstall } from "@/api/outpatient";

export default {
  metaInfo: {
    link: [],
  },
  components: { Navigationen },
  data() {
    return {
      checkboxGroup10: [],
      checkboxGroup11: [],
      checkboxGroup12: [],
      checkboxGroup13: [],
      cities: cityOptions,
      two: false,
      radio2: "",
      accessory_teeth: "",
      ProgrammeID: "",
    };
  },
  mounted() {
    // debugger;
    // 获取患者所有信息
    if (
      localStorage.getItem("ProgrammeID") != null &&
      localStorage.getItem("ProgrammeID") != ""
    ) {
      this.ProgrammeID = JSON.parse(localStorage.getItem("ProgrammeID"));
      getPreinstall(this.ProgrammeID).then((res) => {
        // debugger;
        const { data } = res;
        const {
          unmovable_teeth,
          accessory_teeth,
          interval_teeth,
          adjacent_glaze,
        } = data;

        if (res.code == 200) {
          if (accessory_teeth != null) {
            if (accessory_teeth == 1) {
              this.radio2 = 1;
              this.two = false;
            } else {
              this.radio2 = 2;
              this.two = true;
            }
          }
          this.checkboxGroup10 =
            unmovable_teeth !== null && unmovable_teeth !== undefined
              ? unmovable_teeth
              : this.checkboxGroup10;
          (this.checkboxGroup11 =
            accessory_teeth !== null && accessory_teeth !== undefined
              ? accessory_teeth
              : this.checkboxGroup11),
            (this.checkboxGroup12 =
              interval_teeth !== null && interval_teeth !== undefined
                ? interval_teeth
                : this.checkboxGroup12);
          this.checkboxGroup13 =
            adjacent_glaze !== null && adjacent_glaze !== undefined
              ? adjacent_glaze
              : this.checkboxGroup13;
        }
      });
    }
  },
  methods: {
    Jump(route) {
      this.$router.push(route);
    },
    extractatooth(red) {
      if (red == "2") {
        this.two = true;
      } else {
        this.two = false;
      }
    },
    TreatmentPlan() {
      // this.$router.push("/addCase/transparentMarkerNew");
      if (this.radio2 == "1") {
        this.accessory_teeth = this.radio2;
      } else {
        this.accessory_teeth = this.checkboxGroup11;
      }

      var data = {
        plan_uuid: JSON.parse(localStorage.getItem("ProgrammeID")),
        patient_uuid: JSON.parse(localStorage.getItem("PatientID")),
        user_uuid: JSON.parse(localStorage.getItem("uuid")),
        unmovable_teeth: this.checkboxGroup10,
        accessory_teeth: this.accessory_teeth,
        interval_teeth: this.checkboxGroup12,
        adjacent_glaze: this.checkboxGroup13,
      };
      handle(data).then((res) => {
        if (res.code == 200) {
          this.$router.push("/addCase/dataUploadNew");
        }
      });
    },
  },
};
</script>
  <style scoped>
.container1 {
  width: 100%;
  height: 60px !important;
  background: #00b0fa;
  top: 0px;
  min-width: 1248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rectangle1 {
  position: absolute;
  width: 100%;
  height: 60px;
  right: 0px;
  top: 0px;
  vertical-align: middle;
}
.bannerImg1 {
  min-width: 1248px;
  height: 60px;
  background: rgba(0, 0, 0, 0.64);
}
.header_inner1 {
  padding: 0 calc(50% - 624px);
  background: rgba(0, 0, 0, 0.64);
  min-width: 1248px;
  height: 60px;
}
.logoImg {
  /* height: 40px; */
  /* margin-left: 11.61%; */
  float: left;
  height: 60px;
}
.spanDiv {
  width: calc(100% - 145px);
  /* margin-left: calc(11.61% + 144px); */
  display: inline-block;
  height: 60px;
  font-size: 15px;
  vertical-align: middle;
  clear: both;
}
.spanClass {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}
.spanChoosen {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
}
.addCase-header {
  /* position: absolute; */
  width: 100%;
  height: 98px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  text-align: center;
}
.addCase-header-1 {
  position: relative;
  width: 800px;
  height: 98px;
  left: calc(50% - 800px / 2);
  top: 0px;
}
.addCase-header-item {
  position: relative;
  width: 160px;
  height: 90px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  padding-top: 20px;
  float: left;
  cursor: pointer;
}
.headerActive {
  border-bottom: 3px solid rgba(17, 184, 120, 1);
}
.addCase-header-item-divide-left {
  width: 54px;
  border: 3px solid #f0f0f0;
  position: absolute;
  top: 36px;
  left: 0px;
}
.addCase-header-item-divide-right {
  width: 60px;
  border: 3px solid #f0f0f0;
  position: absolute;
  top: 36px;
}
.addCase-content {
  width: 100%;
  /* min-width: 1248px; */
  height: 1100px;
  display: -webkit-box;
  display: -ms-flexbox;
  background-color: #eef2f6;
  display: flex;
  position: absolute;
}
.patientInfoNew {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.patientInfoNew-content {
  position: absolute;
  width: 960px;
  height: 900px;
  left: calc(50% - 960px / 2);
  top: 24px;
  background: #ffffff;
  padding: 20px 32px 50px 32px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
}

.patientInfoNew_bottom1 {
  position: fixed !important;
  width: 100%;
  height: 64px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ffffff;
}
.patientInfoNew_bottom_content {
  width: 960px;
  height: 64px;
  margin-left: calc(50% - 960px / 2);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #323232;
  position: relative;
}
.patientInfoNew_bottom_content button {
  cursor: pointer;
}
.button-Staging {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 77px);
  top: 7px;
  border: 1px solid rgba(17, 184, 120, 1);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgb(255, 255, 255);
}
.button-step-upper {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 259px);
  top: 7px;
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-step-colour {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 413px);
  top: 7px;
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-return {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc((50% - 67px) - 413px);
  top: 7px;
  border: 1px solid rgba(17, 184, 120, 1);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgb(255, 255, 255);
}
.el-checkbox-button--small {
  margin: 6px 6px;
  border-left: 1px solid #dcdfe6;
}
.el-radio-group >>> .el-radio-button__inner {
  margin: 10px 10px 20px 20px;
  border-left: 1px solid #dcdfe6;
}
.el-checkbox-group
  >>> .el-checkbox-button.is-checked
  .el-checkbox-button__inner {
  background-color: rgba(17, 184, 120, 1);
  border-color: rgba(17, 184, 120, 1);
}
.el-radio-group
  >>> .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background-color: rgba(17, 184, 120, 1);
  border-color: rgba(17, 184, 120, 1);
}
h2,
h3,
h4,
h5,
button,
a {
  font-family: "GenSenMaruGothicTW-Bold";
}
p,
span,
div {
  font-family: "GenSenMaruGothicTW-Regular";
}
</style>