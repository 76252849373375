<template>
  <div>
    <div>
      <div class="container1">
        <div class="rectangle1">
          <Navigationen></Navigationen>
        </div>
      </div>
    </div>
    <div class="addCase-header">
      <div class="addCase-header-1">
        <div class="addCase-header-item">
          <span
            class="addCase-header-item-divide-left"
            style="display: none"
          ></span>
          <img data-v-893d091a="" src="@/img/patient-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Patient information</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img data-v-893d091a="" src="@/img/diagnosis-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Diagnostic information</div>
        </div>
        <div class="addCase-header-item headerActive">
          <span class="addCase-header-item-divide-left"></span>
          <img style="width: 40px" src="@/img/CaseProcess-3.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div>Treatment plan</div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/information-1.png" />
          <span class="addCase-header-item-divide-right"></span>
          <div></div>
        </div>
        <div class="addCase-header-item">
          <span class="addCase-header-item-divide-left"></span>
          <img src="@/img/download-1.png" />
          <span
            class="addCase-header-item-divide-right"
            style="display: none"
          ></span>
          <div>Data upload</div>
        </div>
      </div>
    </div>
    <div class="addCase-content">
      <div class="patientInfoNew">
        <div class="patientInfoNew-content">
          <p
            style="
              font-size: 20px;
              line-height: 160%;
              display: flex;
              align-items: center;
              color: rgb(0, 0, 0);
            "
          >
            3.Treatment plan
          </p>
          <p
            style="
              height: 42px;
              font-size: 13px;
              line-height: 160%;
              color: rgb(85, 85, 85);
              margin-top: 8px;
            "
          >
            You can add up to two lines of filling help or notes here. If there
            is no setting, it will be blank. The text space here is designed to
            effectively solve the matters needing attention in data filling and
            reduce errors.
          </p>
          <img
            src="@/img/beame.png"
            style="
              width: 400px;
              height: 64px;
              position: absolute;
              top: 20px;
              right: 20px;
            "
          />
          <div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="预设选项">
                <el-select v-model="form.region" placeholder="请选择">
                  <el-option label="选项一" value="shanghai"></el-option>
                  <el-option label="选项二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div
              style="
                border: 1px dashed rgb(196, 196, 196);
                margin: 35px 0 35px 0;
              "
            ></div>
            <div>
              <p>1.Preset options</p>
              <el-radio-group v-model="radio1">
                <el-radio-button label="1">Full mouth</el-radio-button>
                <el-radio-button label="2">Upper jaw</el-radio-button>
                <el-radio-button label="3">Lower jaw</el-radio-button>
              </el-radio-group>
            </div>
            <div>
              <p>2.Whether to extract teeth</p>
              <el-radio-group @change="extractatooth" v-model="radio2">
                <el-radio-button label="0">yes</el-radio-button>
                <el-radio-button label="1">no</el-radio-button>
              </el-radio-group>
              <el-checkbox-group
                v-if="this.two == true"
                v-model="checkboxGroup2"
                size="small"
              >
                <el-checkbox-button
                  v-for="city in cities"
                  :label="city"
                  :key="city"
                  >{{ city }}</el-checkbox-button
                >
              </el-checkbox-group>
            </div>
            <div>
              <p>3.支抗</p>
              <p>left side</p>
              <div></div>
              <el-radio-group v-model="radio3Left">
                <el-radio-button label="1"
                  >强:保持后牙矢状向位置</el-radio-button
                >
                <el-radio-button label="2"
                  >中:后牙前移间隙的1/3</el-radio-button
                >
                <el-radio-button label="3"
                  >弱:后牙前移间隙的1/2</el-radio-button
                >
              </el-radio-group>

              <p>Right</p>
              <el-radio-group v-model="radio3right">
                <el-radio-button label="1"
                  >强:保持后牙矢状向位置</el-radio-button
                ><el-radio-button label="2"
                  >中:后牙前移间隙的1/3</el-radio-button
                ><el-radio-button label="3"
                  >弱:后牙前移间隙的1/2</el-radio-button
                >
              </el-radio-group>
            </div>
            <div>
              <p>4.Midline</p>
              <p>Upper jaw</p>
              <el-radio-group v-model="radio4">
                <el-radio-button :disabled="FourUpper" label="1"
                  >Maintain</el-radio-button
                >
                <el-select
                  @change="FourUpperChange"
                  v-model="maxillaryvalue"
                  clearable
                  placeholder="justify"
                >
                  <el-option
                    v-for="item in maxillary"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-if="this.FourUpper == true"
                  style="width: 120px"
                  placeholder="please enter"
                  v-model="center_line_up_val"
                >
                  <template slot="append">mm</template>
                </el-input>
              </el-radio-group>
              <p>Lower jaw</p>
              <el-radio-group :disabled="FourBelow" v-model="radio5">
                <el-radio-button label="1">Maintain</el-radio-button>
                <el-select
                  @change="FourBelowChange"
                  v-model="jawvalue"
                  clearable
                  placeholder="justify"
                >
                  <el-option
                    v-for="item in jaw"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-if="this.FourBelow == true"
                  style="width: 120px"
                  placeholder="please enter"
                  v-model="center_line_low_val"
                >
                  <template slot="append">mm</template>
                </el-input>
              </el-radio-group>
            </div>
            <div>
              <p>5.Overbite</p>
              <el-radio-group v-model="radio6">
                <el-radio-button :disabled="Overlap" label="1"
                  >Maintain</el-radio-button
                >
                <el-cascader
                  v-model="overbite_adjust"
                  @change="FiveOverlap"
                  placeholder="justify"
                  :options="options2"
                  clearable
                ></el-cascader>
              </el-radio-group>
            </div>
            <div>
              <p>6.Overjet</p>
              <el-radio-group v-model="radio7">
                <el-radio-button :disabled="six" label="1"
                  >Maintain</el-radio-button
                >
                <el-select
                  @change="sixChange"
                  v-model="covervalue"
                  clearable
                  placeholder="justify"
                >
                  <el-option
                    v-for="item in cover"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-if="this.six == true"
                  style="width: 120px"
                  placeholder="please enter"
                  v-model="cover_val"
                >
                  <template slot="append">mm</template>
                </el-input>
              </el-radio-group>
            </div>
            <div>
              <p>7.Jaw opening</p>
              <p>
                <span style="margin: 0 260px 0 60px">left side</span>
                <span>Right</span>
              </p>

              <el-radio-group v-model="radio8">
                <el-radio-button :disabled="molar1" label="1"
                  >Maintain</el-radio-button
                >
                <el-select
                  @change="MolarSeven1"
                  v-model="molarLeftvalue"
                  clearable
                  placeholder="justify"
                >
                  <el-option
                    v-for="item in molarLeft"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> </el-radio-group
              ><el-radio-group v-model="radio9">
                <el-radio-button :disabled="molar2" label="1"
                  >Maintain</el-radio-button
                >
                <el-select
                  @change="MolarSeven2"
                  v-model="molarRightvalue"
                  clearable
                  placeholder="justify"
                >
                  <el-option
                    v-for="item in molarRight"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-radio-group>
            </div>
            <div>
              <p>8.Crowded teeth</p>
              <p>upper jaw</p>
              <el-radio-group v-model="radio10">
                <el-radio-button :disabled="EightUpper2" label="1"
                  >none</el-radio-button
                >
                <el-select
                  style="width: 350px"
                  @change="EightUpperChange"
                  v-model="crowdeduppervalue"
                  multiple
                  placeholder="解除拥挤"
                >
                  <el-option
                    v-for="item in crowdedupper"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-if="this.EightUpper == true"
                  style="width: 120px"
                  placeholder="扩弓"
                  v-model="crowding_teeth_up_val"
                >
                  <template slot="append">mm</template>
                </el-input>
              </el-radio-group>
              <p>jaw</p>
              <el-radio-group v-model="radio11">
                <el-radio-button :disabled="EightBelow2" label="1"
                  >none</el-radio-button
                >
                <el-select
                  style="width: 350px"
                  @change="EightBelowChange"
                  v-model="crowdedlowerrvalue"
                  multiple
                  placeholder="解除拥挤"
                >
                  <el-option
                    v-for="item in crowdedlower"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-if="this.EightBelow == true"
                  style="width: 120px"
                  placeholder="扩弓"
                  v-model="crowding_teeth_low_val"
                >
                  <template slot="append">mm</template>
                </el-input>
              </el-radio-group>
            </div>
            <div>
              <p>9.牙齿移动步距角度</p>
              <el-select
                style="margin-right: 30px"
                v-model="toothDistancevalue"
                clearable
                placeholder="距离"
              >
                <el-option
                  v-for="item in toothDistance"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select clearable v-model="toothAnglevalue" placeholder="角度">
                <el-option
                  v-for="item in toothAngle"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div class="patientInfoNew_bottom1">
          <div class="patientInfoNew_bottom_content">
            <button
              @click="Jump('/zh-en/Home')"
              type="button"
              class="button-return"
            >
              <span>return</span>
            </button>
            <button
              @click="TreatmentPlan()"
              type="button"
              class="button-Staging"
            >
              <span>Staging</span>
            </button>
            <button
              @click="Jump('/zh-en/addCase/deagonsticMesNew')"
              type="button"
              class="button-step-upper"
            >
              <span>previous step</span>
            </button>
            <button
              @click="TreatmentPlan()"
              type="button"
              class="button-step-colour"
            >
              <span>next step</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
const cityOptions = [
  "18",
  "17",
  "16",
  "15",
  "14",
  "13",
  "12",
  "11",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "48",
  "47",
  "46",
  "45",
  "44",
  "43",
  "42",
  "41",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
];
import Navigationen from "@/components/Navigationen.vue";
import { handle, getPreinstall } from "@/api/outpatient";
export default {
  metaInfo: {
    link: [],
  },
  components: { Navigationen },
  data() {
    return {
      checkboxGroup2: [],
      cities: cityOptions,
      options2: [
        {
          value: "1",
          label: "压低上下前牙",
          children: [
            {
              value: "1",
              label: "正常",
            },
            {
              value: "2",
              label: "I度深覆",
            },
            {
              value: "3",
              label: "II度深覆",
            },
            {
              value: "4",
              label: "III度深覆",
            },
          ],
        },
        {
          value: "2",
          label: "压低上前牙",
          children: [
            {
              value: "1",
              label: "正常",
            },
            {
              value: "2",
              label: "I度深覆",
            },
            {
              value: "3",
              label: "II度深覆",
            },
            {
              value: "4",
              label: "III度深覆",
            },
          ],
        },
        {
          value: "3",
          label: "压低下前牙",
          children: [
            {
              value: "1",
              label: "正常",
            },
            {
              value: "2",
              label: "I度深覆",
            },
            {
              value: "3",
              label: "II度深覆",
            },
            {
              value: "4",
              label: "III度深覆",
            },
          ],
        },
      ],

      form: {
        region: "",
      },
      radio1: "",
      radio2: "",
      radio3Left: "",
      radio3right: "",
      radio4: "",
      radio5: "",
      radio6: "",
      radio7: "",
      radio8: "",
      radio9: "",
      radio10: "",
      radio11: "",

      maxillary: [
        {
          value: "2",
          label: "左调整",
        },
        {
          value: "3",
          label: "右调整",
        },
        {
          value: "4",
          label: "justify",
        },
      ],
      maxillaryvalue: "",
      jaw: [
        {
          value: "2",
          label: "左调整",
        },
        {
          value: "3",
          label: "右调整",
        },
        {
          value: "4",
          label: "justify",
        },
      ],
      jawvalue: "",
      cover: [
        {
          value: "2",
          label: "正常",
        },
        {
          value: "3",
          label: "轻度",
        },
        {
          value: "4",
          label: "中度",
        },
        {
          value: "5",
          label: "重度",
        },
      ],
      covervalue: "",
      molarLeft: [
        {
          value: "2",
          label: "中性",
        },
        {
          value: "3",
          label: "近中",
        },
        {
          value: "4",
          label: "远中",
        },
      ],
      molarLeftvalue: "",
      molarRight: [
        {
          value: "2",
          label: "中性",
        },
        {
          value: "3",
          label: "近中",
        },
        {
          value: "4",
          label: "远中",
        },
      ],
      molarRightvalue: "",
      crowdedupper: [
        {
          value: "2",
          label: "扩弓",
        },
        {
          value: "3",
          label: "邻面去釉",
        },
        {
          value: "4",
          label: "唇展",
        },
        {
          value: "5",
          label: "磨牙远移",
        },
      ],
      crowdeduppervalue: [],
      crowdedlower: [
        {
          value: "2",
          label: "扩弓",
        },
        {
          value: "3",
          label: "邻面去釉",
        },
        {
          value: "4",
          label: "唇展",
        },
        {
          value: "5",
          label: "磨牙远移",
        },
      ],
      crowdedlowerrvalue: [],
      toothDistance: [
        {
          value: "1",
          label: "0.15mm",
        },
        {
          value: "2",
          label: "0.2mm",
        },
        {
          value: "3",
          label: "0.25mm",
        },
      ],
      toothDistancevalue: "",
      toothAngle: [
        {
          value: "1",
          label: "1°",
        },
        {
          value: "2",
          label: "2°",
        },
        {
          value: "3",
          label: "3°",
        },
      ],
      toothAnglevalue: "",
      value: "",
      FourUpper: false,
      FourBelow: false,
      Overlap: false,
      molar1: false,
      molar2: false,
      EightUpper: false,
      EightUpper2: false,
      EightBelow: false,
      EightBelow2: false,
      six: false,
      two: false,

      center_line_up_val: "",
      center_line_low_val: "",
      center_line_choice_up: "",
      center_line_choice_low: "",
      overbite_adjust: [],
      cover_adjust: "",
      cover_val: "",
      molar_relationship_left: "",
      molar_relationship_right: "",
      crowding_teeth_low: [],
      crowding_teeth_up: [],
      crowding_teeth_low_val: "",
      crowding_teeth_up_val: "",
      tooth_pulling: {},
      DoctorID: "",
      ProgrammeID: "",
    };
  },
  computed: {},
  mounted() {
    // 获取患者所有信息
    if (
      localStorage.getItem("ProgrammeID") != null &&
      localStorage.getItem("ProgrammeID") != ""
    ) {
      this.ProgrammeID = JSON.parse(localStorage.getItem("ProgrammeID"));
      getPreinstall(this.ProgrammeID).then((res) => {
        if (res.code == 200) {
          const dataMap = {
            orthodontic_arch: "radio1",
            anchorage_left: "radio3Left",
            anchorage_right: "radio3right",
            center_line_low_val: "center_line_low_val",
            center_line_up_val: "center_line_up_val",
            cover_val: "cover_val",
            crowding_teeth_low_val: "crowding_teeth_low_val",
            crowding_teeth_up_val: "crowding_teeth_up_val",
            angle: "toothDistancevalue",
            move: "toothAnglevalue",
          };
          for (const key in dataMap) {
            if (res.data[key] !== null) {
              this[dataMap[key]] = String(res.data[key]);
            }
          }

          if (res.data.tooth_pulling != null) {
            (this.radio2 =
              res.data.tooth_pulling == "1"
                ? res.data.tooth_pulling
                : this.radio2),
              (this.two = true);
            this.checkboxGroup2 =
              res.data.tooth_pulling != "1"
                ? res.data.tooth_pulling
                : this.checkboxGroup2;
          }
          if (res.data.center_line_choice_up != null) {
            this.radio4 =
              res.data.center_line_choice_up == "1"
                ? res.data.center_line_choice_up
                : this.radio4;
            this.maxillaryvalue =
              res.data.center_line_choice_up != "1"
                ? String(res.data.center_line_choice_up)
                : this.maxillaryvalue;
            this.FourUpper = res.data.center_line_choice_up != "1";
          }
          if (res.data.center_line_choice_low != null) {
            this.radio5 =
              res.data.center_line_choice_low == "1"
                ? res.data.center_line_choice_low
                : this.radio5;
            this.jawvalue =
              res.data.center_line_choice_low != "1"
                ? String(res.data.center_line_choice_low)
                : this.jawvalue;
            this.FourBelow = res.data.center_line_choice_low != "1";
          }
          if (res.data.overbite_adjust != null) {
            this.radio6 =
              res.data.overbite_adjust == "1"
                ? res.data.overbite_adjust
                : this.radio6;
            this.overbite_adjust =
              res.data.overbite_adjust != "1"
                ? res.data.overbite_adjust
                : this.overbite_adjust;
          }
          const {
            cover_adjust,
            molar_relationship_left,
            molar_relationship_right,
            crowding_teeth_up,
            crowding_teeth_low,
          } = res.data;

          this.radio7 = cover_adjust === "1" ? "1" : "";
          this.covervalue = cover_adjust.toString() || "";
          this.six = !cover_adjust;

          this.radio8 = molar_relationship_left === "1" ? "1" : "";
          this.molarLeftvalue = molar_relationship_left.toString() || "";

          this.radio9 = molar_relationship_right === "1" ? "1" : "";
          this.molarRightvalue = molar_relationship_right.toString() || "";

          this.radio10 = crowding_teeth_up === "1" ? "1" : "";
          this.crowdeduppervalue = crowding_teeth_up ? crowding_teeth_up : "";
          this.EightUpper =
            crowding_teeth_up && crowding_teeth_up.includes("2");

          this.radio11 = crowding_teeth_low === "1" ? "1" : "";
          this.crowdedlowerrvalue = crowding_teeth_low
            ? crowding_teeth_low
            : "";
          this.EightBelow =
            crowding_teeth_low && crowding_teeth_low.includes("2");
        }
      });
    }
  },
  methods: {
    Jump(route) {
      this.$router.push(route);
    },

    TreatmentPlan() {
      // this.$router.push("/addCase/transparentMarkerNew");
      if (this.radio2 == "0") {
        this.tooth_pulling = this.checkboxGroup2;
      } else {
        this.tooth_pulling = this.radio2;
      }
      if (this.radio4 == "1") {
        this.center_line_choice_up = this.radio4;
      }
      if (this.radio5 == "1") {
        this.center_line_choice_low = this.radio5;
      }
      if (this.radio6 == "1") {
        this.overbite_adjust = this.radio6;
      }
      if (this.radio7 == "1") {
        this.cover_adjust = this.radio7;
      }
      if (this.radio8 == "1") {
        this.molar_relationship_left = this.radio8;
      }
      if (this.radio9 == "1") {
        this.molar_relationship_right = this.radio9;
      }
      if (this.radio10 == "1") {
        this.crowding_teeth_up = this.radio10;
      }
      if (this.radio11 == "1") {
        this.crowding_teeth_low = this.radio11;
      }

      var data = {
        plan_uuid: JSON.parse(localStorage.getItem("ProgrammeID")),
        patient_uuid: JSON.parse(localStorage.getItem("PatientID")),
        user_uuid: JSON.parse(localStorage.getItem("DoctorID")),
        orthodontic_arch: this.radio1,
        tooth_pulling: this.tooth_pulling,
        anchorage_left: this.radio3Left,
        anchorage_right: this.radio3right,
        center_line_choice_low: this.center_line_choice_low,
        center_line_low_val: this.center_line_low_val,
        center_line_choice_up: this.center_line_choice_up,
        center_line_up_val: this.center_line_up_val,
        overbite_adjust: this.overbite_adjust,
        cover_adjust: this.cover_adjust,
        cover_val: this.cover_val,
        molar_relationship_left: this.molar_relationship_left,
        molar_relationship_right: this.molar_relationship_right,
        crowding_teeth_low: this.crowding_teeth_low,
        crowding_teeth_low_val: this.crowding_teeth_low_val,
        crowding_teeth_up: this.crowding_teeth_up,
        crowding_teeth_up_val: this.crowding_teeth_up_val,
        angle: this.toothDistancevalue,
        move: this.toothAnglevalue,
        // unmovable_teeth: this.imageUuid_13,
        // accessory_teeth: this.imageUuid_13,
        // interval_teeth: this.imageUuid_13,
        // adjacent_glaze: this.imageUuid_13,
      };
      handle(data).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("ProgrammeID", JSON.stringify(res.data.uuid));

          this.$router.push("/zh-en/addCase/transparentMarkerNew");
        }
      });
    },

    sixChange(red) {
      if (red == "") {
        this.six = false;
      } else {
        this.six = true;
        this.cover_adjust = this.covervalue;
      }
    },
    FourUpperChange(red) {
      if (red == "") {
        this.FourUpper = false;
      } else {
        this.FourUpper = true;
        this.center_line_choice_up = this.maxillaryvalue;
      }
    },
    FourBelowChange(red) {
      if (red == "") {
        this.FourBelow = false;
      } else {
        this.FourBelow = true;
        this.center_line_choice_low = this.jawvalue;
      }
    },
    FiveOverlap(red) {
      if (red == "") {
        this.Overlap = false;
      } else {
        this.Overlap = true;
        this.overbite_adjust = this.overbite_adjust;
      }
    },
    MolarSeven1(red) {
      if (red == "") {
        this.molar1 = false;
      } else {
        this.molar1 = true;
        this.molar_relationship_left = this.molarLeftvalue;
      }
    },
    MolarSeven2(red) {
      if (red == "") {
        this.molar2 = false;
      } else {
        this.molar2 = true;
        this.molar_relationship_right = this.molarRightvalue;
      }
    },
    EightBelowChange(red) {
      if (
        red.includes("2") ||
        red.includes("3") ||
        red.includes("4") ||
        red.includes("5")
      ) {
        this.EightBelow2 = true;
        if (red.includes("2")) {
          this.EightBelow = true;
        }
        this.crowding_teeth_low = this.crowdedlowerrvalue;
      } else {
        this.EightBelow = false;
        this.crowding_teeth_low = this.crowdedlowerrvalue;
      }
    },
    EightUpperChange(red) {
      if (
        red.includes("2") ||
        red.includes("3") ||
        red.includes("4") ||
        red.includes("5")
      ) {
        this.EightUpper2 = true;
        if (red.includes("2")) {
          this.EightUpper = true;
        }
        this.crowding_teeth_up = this.crowdeduppervalue;
      } else {
        this.EightUpper = false;
        this.crowding_teeth_up = this.crowdeduppervalue;
      }
    },
    extractatooth(red) {
      if (red == "0") {
        this.two = true;
      } else {
        this.two = false;
      }
    },
  },
};
</script>
    <style scoped>
.container1 {
  width: 100%;
  height: 60px !important;
  background: #00b0fa;
  top: 0px;
  min-width: 1248px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rectangle1 {
  position: absolute;
  width: 100%;
  height: 60px;
  right: 0px;
  top: 0px;
  vertical-align: middle;
}
.bannerImg1 {
  min-width: 1248px;
  height: 60px;
  background: rgba(0, 0, 0, 0.64);
}
.header_inner1 {
  padding: 0 calc(50% - 624px);
  background: rgba(0, 0, 0, 0.64);
  min-width: 1248px;
  height: 60px;
}
.logoImg {
  /* height: 40px; */
  /* margin-left: 11.61%; */
  float: left;
  height: 60px;
}
.spanDiv {
  width: calc(100% - 145px);
  /* margin-left: calc(11.61% + 144px); */
  display: inline-block;
  height: 60px;
  font-size: 15px;
  vertical-align: middle;
  clear: both;
}
.spanClass {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.51);
  margin-left: 32px;
  line-height: 40px;
  cursor: pointer;
}
.spanChoosen {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 5px;
  border-bottom: 1px solid #ffffff;
}
.addCase-header {
  /* position: absolute; */
  width: 100%;
  height: 98px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  text-align: center;
}
.addCase-header-1 {
  position: relative;
  width: 1023px;
  left: calc(50% - 960px / 2);
  height: 98px;
  top: 0px;
}
.addCase-header-item {
  position: relative;
  width: 204px;
  height: 90px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  padding-top: 20px;
  float: left;
  cursor: pointer;
}
.headerActive {
  border-bottom: 3px solid rgba(17, 184, 120, 1);
}
.addCase-header-item-divide-left {
  width: 80px;
  border: 3px solid #f0f0f0;
  position: absolute;
  top: 36px;
  left: 0px;
}
.addCase-header-item-divide-right {
  width: 80px;
  border: 3px solid #f0f0f0;
  position: absolute;
  top: 36px;
}
.addCase-content {
  width: 100%;
  /* min-width: 1248px; */
  height: 2100px;
  display: -webkit-box;
  display: -ms-flexbox;
  background-color: #eef2f6;
  display: flex;
  position: absolute;
}
.patientInfoNew {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.patientInfoNew-content {
  position: absolute;
  width: 960px;
  height: 1900px;
  left: calc(50% - 960px / 2);
  top: 24px;
  background: #ffffff;
  padding: 20px 32px 50px 32px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
}

.patientInfoNew_bottom1 {
  position: fixed !important;
  width: 100%;
  height: 64px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ffffff;
}
.patientInfoNew_bottom_content {
  width: 960px;
  height: 64px;
  margin-left: calc(50% - 960px / 2);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #323232;
  position: relative;
}
.patientInfoNew_bottom_content button {
  cursor: pointer;
}
.upload-demo {
  margin: 0 20px;
  float: left;
}
.upload-demo >>> .el-upload-dragger {
  width: 200px;
}

.button-Staging {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 77px);
  top: 7px;
  border: 1px solid rgba(17, 184, 120, 1);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgb(255, 255, 255);
}
.button-step-upper {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 259px);
  top: 7px;
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-step-colour {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc(50% - 67px + 413px);
  top: 7px;
  background: rgba(17, 184, 120, 1);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  border: none;
}
.button-return {
  position: absolute;
  width: 134px;
  height: 40px;
  left: calc((50% - 67px) - 413px);
  top: 7px;
  border: 1px solid rgba(17, 184, 120, 1);
  box-sizing: border-box;
  border-radius: 4px;
  background: rgb(255, 255, 255);
}
.el-radio-group >>> .el-radio-button__inner {
  margin: 10px 10px 20px 20px;
  border-left: 1px solid #dcdfe6;
}
/* .el-radio-group >>> .el-radio-button__inner {
  width: 100px;
} */
.el-input-group--append >>> .el-input-group__append {
  padding: 0 3px;
}
.el-checkbox-button--small {
  margin: 6px 6px;
  border: 1px solid #dcdfe6;
}
.el-checkbox-group
  >>> .el-checkbox-button.is-checked
  .el-checkbox-button__inner {
  background-color: rgba(17, 184, 120, 1);
  border-color: rgba(17, 184, 120, 1);
}
.el-radio-group
  >>> .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background-color: rgba(17, 184, 120, 1);
  border-color: rgba(17, 184, 120, 1);
}
.el-radio-group
  >>> .el-radio-button__orig-radio:disabled:checked
  + .el-radio-button__inner {
  background-color: #fff !important;
}
h2,
h3,
h4,
h5,
button,
span,
a {
  font-family: "Omnium-Bold";
}
p,
div {
  font-family: "Comfortaa-Bold";
}
</style>